import * as types from '../constants/action-types';
const initialState = {
    loading : false
}

export function loading(state = initialState, action) {
    switch (action.type) {
        case types.LOADING:
            return {
                ...state, loading: action.data
            };
        default:
            return state
    }
}