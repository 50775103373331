import React, {Component} from 'react';
import {withRouter} from 'react-router';
// import IndexHeader from "../components/Headers/IndexHeader.js";
// import SearchHeader from "../components/Headers/SearchHeader.js";
import JaminanHalalHeader from "../components/Headers/JaminanHalalHeader.js";
// import DemoFooter from "../components/Footers/DemoFooter.js";
import FooterDark from "../components/Footers/FooterDark";
import FooterSubscribe from "../components/Footers/FooterSubscribe";
import { Row,Col,UncontrolledCarousel } from "reactstrap";
import {connect} from 'react-redux';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import {Link} from "react-router-dom";
import {SertifikatActions,ManagementActions} from "../../../redux/actions";
    
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width:undefined,
            mobileThreshold:768,
            sertificate:[
                // {
                //     src:require('../assets/img/sierad/sertifikat.jpg'),
                //     altText:'Sertifikat',
                //     caption: 'Majelis Ulama Indonesia'
                // },
                // {
                //     src:require('../assets/img/sierad/sertifikat.jpg'),
                //     altText:'Sertifikat SJH',
                //     caption: 'Sistem Jaminan Halal'
                // },
            ],
            tim_management:[
                // {
                //     src: "1Xd3wx1fLPcMIKN1CSE1yklypuyRJ1O7h",
                //     altText: "foto-1",
                //     name: "Wahyu",
                //     jabatan: "manager chilling"
                // },
                // {
                //     src: "1Xd3wx1fLPcMIKN1CSE1yklypuyRJ1O7h",
                //     altText: "foto-1",
                //     name: "Wahyu",
                //     jabatan: "manager chilling"
                // },
            ],
            fasilitas_produksi:[
                {
                    src:require('../assets/img/sierad/fasilitas_1.jpg').default,
                    altText:'alat potong',
                    caption: ''
                },
                // {
                //     src:require('../assets/img/sierad/bersih-jeroan.jpg'),
                //     altText:'sackle',
                //     caption: ''
                // },
            ],
            prosedur_aktivitas_kritis:[
                {
                    src:require('../assets/img/sierad/ceksuhu.jpg').default,
                    altText:'alat potong',
                    caption: ''
                },
            ],
            audit_internal:[
                {
                    src:require('../assets/img/sierad/audit_1.jpg').default,
                    altText:'alat potong',
                    caption: ''
                },
            ],
            pelatihan_halal:'internal'
        }
    }
    handleResize = () => {
        this.setState({
            width: window.innerWidth,
        });
    };
    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    };
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    };

    onChangePelatihanHalal =(name)=>{
        if(this.state.pelatihan_halal==="internal"){
            this.setState({
                [name]:'eksternal'
            })
        } else {
            this.setState({
                [name]:'internal'
            })
        }
    }
    componentWillMount(){
        this.getSertificate();
        this.getTimManagement();
    }

    getSertificate=async()=>{
        const {dispatch} = this.props;
        let response = await dispatch(SertifikatActions.getListSertifikat());
        if(response){
            if(!response.data.error){
                let data = response.data;
                let temp = [];
                for(let i=0;i<data.length;i++){
                    temp.push({
                        // src:'https://lh3.googleusercontent.com/u/0/d/'+data[i].src+'=w1366-h657-iv1',
                        src:data[i].src,
                        altText:data[i].altText,
                        caption:data[i].altText,
                    })
                }
                this.setState({
                    sertificate:temp
                })
            } else {
                this.setState({
                    sertificate:[]
                })
            }
        } else {
            this.setState({
                sertificate:[]
            })
        }
    }
    getTimManagement=async()=>{
        const {dispatch} = this.props;
        let response = await dispatch(ManagementActions.getListManagement());
        if(response){
            if(!response.data.error){
                let data = response.data;
                this.setState({
                    tim_management:data
                })
            } else {
                this.setState({
                    tim_management:[]
                })
            }
        } else {
            this.setState({
                tim_management:[]
            })
        }
    }

    componentDidUpdate(prevProps,state) {
        if(state.sertificate.length === 0){
            if(prevProps.location.state){
                if (this.props.location.pathname !== prevProps.location.state.prevPath) {
                window.scrollTo(0, 0)
                }
            }
        }
    }

    render() {
        const {width,mobileThreshold} = this.state;
        return (
            <div>
                {/* <IndexHeader /> */}
                <div className="main jaminan-halal">
                    <JaminanHalalHeader/>
                    <section id="sistem-jaminan-halal" className='section-lg section' style={{backgroundColor:'white', padding:'6rem 0 1rem 0'}}>
                        <div className='container'>
                            <div style={{textAlign:'center'}}>
                                <h1 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 -1rem 0', fontSize:'3.5rem'}}>Apa itu</h1>
                                <h3 style={{fontWeight:'800',margin:'0 0 2rem 0'}}>Sistem Jaminan Halal?</h3>
                                <p style={{fontWeight:'400'}}>
                                    Sistem Jaminan Halal (SJH) adalah sistem manajemen terintegrasi yang disusun, diterapkan dan dipelihara untuk mengatur bahan, proses produksi, produk, sumber daya manusia dan prosedur dalam rangka menjaga kesinambungan proses produksi halal sesuai dengan persyaratan LPPOM MUI. 
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className='section-lg section kebijakan-halal' style={{backgroundColor:'white',padding: '3rem 0 0 0'}}>
                        <div className='container'>
                            <Row>
                                <Col md='6' lg="6">
                                    <div className='kebijakan-halal-content'>
                                        <h3 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>01</h3>
                                        <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Kebijakan Halal</h3>
                                        <p style={{fontWeight:'400'}}>
                                            Sreeya Sewu Indonesia berkomitmen untuk menghasilkan produk halal yang berasal dari ayam yang sehat, bernilai gizi tinggi sesuai dengan standar kesehatan melalui prosedur ketat dengan fasilitas modern sesuai dengan standar Mutu dan Keamanan Pangan.
                                        </p>
                                    </div>
                                </Col>
                                <Col md='6' lg='6'>
                                    <div className='carousel-sertificate'>
                                        <UncontrolledCarousel items={this.state.sertificate}/>
                                        {/* {this.state.sertificate.map((sertificate,idx)=>{
                                            return(
                                                <img key={idx} style={{width:'100%'}} src={'https://lh3.googleusercontent.com/u/0/d/'+sertificate.src+'=w1366-h657-iv1'} alt={sertificate.altText}/>
                                            )
                                        })} */}
                                    </div>
                                </Col>
                                <Col lg='12'>
                                    <div style={{textAlign:'right'}}>
                                        <Fade left>
                                            <h3 className="large-font" style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0'}}>Bersertifikat</h3>
                                        </Fade>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section-lg section manajemen-halal' style={{backgroundColor:'white',padding: '3rem 0 0 0'}}>
                        <div className='container'>
                            <Row>
                                {(width>=mobileThreshold)?(
                                <Col md='6' lg='6'>
                                    <div className='management-halal-photo'>
                                        {this.state.tim_management.map((tim,idx)=>{
                                            return (
                                                <div className='management-halal-photo-box' key={idx}>
                                                    {(tim.src)?
                                                        <img src={tim.src} alt={tim.altText}/>
                                                    :
                                                        <img src={require('../assets/img/default-avatar.png').default} alt="default"/>
                                                    }
                                                    <div className="management-halal-photo-content-top">
                                                        <p className="management-halal-photo-box-name">{tim.name}</p>
                                                        <p className="management-halal-photo-box-jabatan">{tim.jabatan}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Col>
                                ):''}
                                <Col md='6' lg="6">
                                    <div className="manajemen-halal-content">
                                        <h3 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>02</h3>
                                        <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Manajemen Halal</h3>
                                        <p style={{fontWeight:'400', textAlign:'right'}}>
                                        Untuk  menerapkan  SJH dan  dalam  rangka menjaga  konsistensi  kehalalan  produk, dengan  ini ditunjuk  Tim  Manajemen  Halal yang terdiri  dari  pimpinan  perusahaan  dan karyawan.  Tugas  Tim  Manajemen  Halal  adalah  melaksanakan  dengan sungguh-sungguh semua prosedur operasional halal sesuai dengan ketentuan sertifikasi LPPOM MUI tentang Sistem Jaminan Halal. Tim Manajemen Halal telah membaca dan memahami Manual SJH serta akan melaksanakan dengan sungguh-sungguh semua prosedur operasional seperti yang tertulis pada Manual SJH.
                                        </p>
                                    </div>
                                </Col>
                                {(width<mobileThreshold)?(
                                <Col md='6' lg='6'>
                                    <div className='management-halal-photo'>
                                        {(this.state.tim_management.map((tim,idx)=>{
                                            return (
                                                <div className='management-halal-photo-box' key={idx}>
                                                    {(tim.src)?
                                                        <img src={tim.src} alt={tim.altText}/>
                                                    :
                                                        <img src={require('../assets/img/default-avatar.png').default} alt="default"/>
                                                    }
                                                    <div className="management-halal-photo-content-top">
                                                        <p className="management-halal-photo-box-name">{tim.name}</p>
                                                        <p className="management-halal-photo-box-jabatan">{tim.jabatan}</p>
                                                    </div>
                                                </div>
                                            )
                                        }))}    
                                    </div>
                                </Col>
                                ):''}
                                <Col lg='12'>
                                    <div style={{textAlign:'left'}}>
                                        <Fade right>
                                            <h3 className="large-font" style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0'}}>Manajemen</h3>
                                        </Fade>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section-lg section pelatihan-halal' style={{backgroundColor:'white', padding:'3rem 0 0 0'}}>
                        <div className='container' style={{position:'relative'}}>
                            <div className="pelatihan-halal-control">
                                <a href="#!" role="button" onClick={()=>this.onChangePelatihanHalal('pelatihan_halal')}>
                                    <span className="pelatihan-halal-prev-icon">
                                        <i className='fas fa-angle-left'></i>
                                    </span>
                                </a>
                                <a href="#!" role="button" onClick={()=>this.onChangePelatihanHalal('pelatihan_halal')}>
                                    <span className="pelatihan-halal-next-icon">
                                        <i className='fas fa-angle-right'></i>
                                    </span>
                                </a>
                            </div>
                            <div 
                                // className={(this.state.pelatihan_halal==="internal")?"pelatihan-halal-internal active slide-in":"pelatihan-halal-internal slide-out"}
                            >
                                {(this.state.pelatihan_halal==="internal")?(
                                <Fade left>
                                <Row>
                                    <Col md='7' style={{padding:'0'}}>
                                        <div>
                                            <img src={require('../assets/img/sierad/pelatihan_1.jpg').default} alt="pelatihan internal"/>
                                        </div>
                                    </Col>
                                    <Col md='5' style={{padding:'0'}}>
                                        <div className="pelatihan-halal-content">
                                            <h3 style={{fontWeight:'800',color:'#5FA77D', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>03</h3>
                                            <h3 style={{fontWeight:'800',margin:'0 0 4px 0', color:'white'}}>Pelatihan Halal</h3>
                                            <div style={{
                                                margin:'0 0 1rem 0',
                                                color:'white', 
                                                backgroundColor:'#E99600',
                                                padding:'3px 12px',
                                                display:'inline-block',
                                                borderRadius:'13px'
                                            }}
                                            >
                                                <p style={{margin:'0',color:'white'}}>Internal</p>
                                            </div>
                                            <p style={{fontWeight:'400', textAlign:'left', color:'white'}}>
                                                Pelatihan HAS 23000 yang diselenggarakan oleh internal perusahaan
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                </Fade>
                                ):''}
                            </div>
                            <div 
                                // className={(this.state.pelatihan_halal==="eksternal")?"pelatihan-halal-eksternal active slide-in":"pelatihan-halal-eksternal slide-out"}
                            >
                                {(this.state.pelatihan_halal==="eksternal")?(
                                <Fade right>
                                <Row>
                                    <Col md='7' style={{padding:'0'}}>
                                        <div>
                                            <img src={require('../assets/img/sierad/pelatihan_2.jpg').default} alt="pelatihan eksternal"/>
                                        </div>
                                    </Col>
                                    <Col md='5' style={{padding:'0'}}>
                                        <div className="pelatihan-halal-content">
                                            <h3 style={{fontWeight:'800',color:'#5FA77D', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>03</h3>
                                            <h3 style={{fontWeight:'800',margin:'0 0 4px 0', color:'white'}}>Pelatihan Halal</h3>
                                            <div style={{
                                                margin:'0 0 1rem 0',
                                                color:'white', 
                                                backgroundColor:'#E99600',
                                                padding:'3px 12px',
                                                display:'inline-block',
                                                borderRadius:'13px'
                                            }}
                                            >
                                                <p style={{margin:'0',color:'white'}}>Eksternal</p>
                                            </div>
                                            <p style={{fontWeight:'400', textAlign:'left', color:'white'}}>
                                                Pelatihan HAS 23000 yang diselenggarakan oleh atau atas nama LPPOM MUI
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                </Fade>
                                ):''}
                            </div>
                        </div>
                    </section>
                    <section className='section-lg section' style={{backgroundColor:'white', padding:'5rem 0 3rem 0'}}>
                        <div className='container'>
                            <div className="jaminan-halal" style={{textAlign:'center'}}>
                                <h3 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>04</h3>
                                <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Roadmap Halal</h3>
                                <p style={{fontWeight:'400'}}>
                                    Produk kami telah melalui serangkaian proses yang panjang, prosedur yang ketat, standar yang tinggi, dan pengecekan mutu yang baik sehingga menghasilkan produk terbaik pula. Bagaimana kami memproses ayam pilihan kami dalam rumah potong kami?                                </p>
                                <div>
                                    <Zoom>
                                        <img style={{width:'100%'}} src={require('../assets/img/sierad/roadmap.png').default} alt="roadmap halal"/>
                                    </Zoom>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='section-lg section sierad-product' style={{backgroundColor:'#FCEFD9', padding:'5rem 0 5rem 0'}}>
                        <div className='container'>
                            <div className="jaminan-halal" style={{textAlign:'center'}}>
                                <h3 style={{fontWeight:'800',color:'#FCDEA4', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>05</h3>
                                <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Produk</h3>
                                <p style={{fontWeight:'400',marginBottom:'1rem'}}>
                                    Produk pada industri pengolahan adalah produk yang didaftarkan untuk sertifikasi halal, baik berupa produk retail, non retail, produk akhir                                </p>
                                <div>
                                    <Row>
                                        <Col sm="4" md="4">
                                            <div className="sierad-product-box">
                                                <div className="sierad-product-box-img">
                                                    <img style={{width:'80%'}} src={require('../assets/img/sierad/karkas-fix.png').default} alt="karkas"/>
                                                </div>
                                                <div className="sierad-product-box-content">
                                                    <p>Karkas</p>
                                                    <a href="https://www.sreeyasewu.com/id/products/broilers">view more</a>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4" md="4">
                                            <div className="sierad-product-box">
                                                <div className="sierad-product-box-img">
                                                    <img style={{width:'80%'}} src={require('../assets/img/sierad/boneless-fix.png').default} alt="boneless"/>
                                                </div>
                                                <div className="sierad-product-box-content">
                                                    <p>Boneless</p>
                                                    <a href="https://www.sreeyasewu.com/id/products/broilers">view more</a>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4" md="4">
                                            <div className="sierad-product-box">
                                                <div className="sierad-product-box-img">
                                                    <img style={{width:'80%'}} src={require('../assets/img/sierad/parting-fix.png').default} alt="parting"/>
                                                </div>
                                                <div className="sierad-product-box-content">
                                                    <p>Parting</p>
                                                    <a href="https://www.sreeyasewu.com/id/products/broilers">view more</a>
                                                </div>
                                            </div>
                                        </Col>
                                        {/* <Col sm="6" md="3">
                                            <div className="sierad-product-box">
                                                <div className="sierad-product-box-img">
                                                    <img style={{width:'100%'}} src={require('../assets/img/sierad/marinasi-fix.png')} alt="marinasi"/>
                                                </div>
                                                <div className="sierad-product-box-content">
                                                    <p>Marinasi</p>
                                                    <a href="https://www.sreeyasewu.com/id/products/broilers">view more</a>
                                                </div>
                                            </div>
                                        </Col> */}
                                    </Row>
                                </div>
                                <div style={{textAlign:'right'}}>
                                    <Fade left>
                                        <h3 className="large-font" style={{fontWeight:'800',color:'#FCDEA4', margin:'0 0 1rem 0'}}>Kategori</h3>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='section-lg section' style={{backgroundColor:'white', padding:'5rem 0 0 0'}}>
                        <div className='container'>
                            <Row>
                                <Col md='6' lg='6'>
                                    <div style={{textAlign:'center'}}>
                                        <UncontrolledCarousel items={this.state.fasilitas_produksi}/>
                                    </div>
                                </Col>
                                <Col md='6' lg="6">
                                    <div>
                                        <h3 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>06</h3>
                                        <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Fasilitas Produksi</h3>
                                        <p style={{fontWeight:'400', textAlign:'left'}}>
                                            Fasilitas Produksi halal adalah semua lini produksi dan peralatan  pembantu yang digunakan untuk  menghasilkan produk terjamin halalnya dan bebas dari najis. Sreeya menjamin kebersihan seluruh fasilitas produksi sehingga menghasilkan produk yang halal dan berkualitas.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section-lg section' style={{backgroundColor:'white', padding:'3rem 0 5rem 0'}}>
                        <div className='container'>
                            <Row>
                                <Col lg='12'>
                                    <div style={{textAlign:'center'}}>
                                        <UncontrolledCarousel items={this.state.prosedur_aktivitas_kritis}/>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <Row>
                                        <Col md='3'>
                                            <div>
                                                <h3 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>07</h3>
                                                <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Prosedur Aktivitas Kritis</h3>
                                            </div>
                                        </Col>
                                        <Col md='9'>
                                            <div style={{marginTop:'5%'}}>
                                                <p style={{fontWeight:'400', textAlign:'left'}}>
                                                Dalam melakukan aktivitas produksi, Rumah Potong Ayam Sreeya memiliki sejumlah prosedur tertulis yang telah melalui persetujuan LPPOM MUI seperti prosedur penyeleksian dan pembelian bahan, pengembangan produk, pemeriksaan bahan datang, proses produksi, pencucian fasilitas produksi, penyimpanan, transportasi, hingga aturan pengunjung dan karyawan.                                        
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section-lg section telusuri-proses-produksi' style={{backgroundColor:'#008236', padding:'5rem 0 0 0'}}>
                        <div className='container'>
                            <Row>
                                <Col lg="12">
                                    <Row>
                                    {(width>=mobileThreshold)?(
                                        <Col md='9'>
                                            <div style={{marginTop:'1%', textAlign:'right'}}>
                                                <p style={{fontWeight:'400', color:'#f0f0f0'}}>
                                                Kami berupaya untuk selalu menjaga ketelusuran produk kami dimana seluruh produk yang tersertifikasi berasal dari bahan-bahan yang telah disetujui dan diproduksi pada fasilitas yang memenuhi kriteria halal. Halal tracker, yang kami inisiasi, adalah salah satu langkah kami dalam mengedepankan informasi ketelusuran halal produk kami secara bertahap.
                                                </p>
                                                <Link to={{pathname: '/produksi-halal', state: { prevPath: window.location.pathname }}}>
                                                    <button>Telusuri Proses Produksi</button>
                                                </Link>
                                            </div>
                                        </Col>
                                    ):''}
                                    {(width>=mobileThreshold)?(
                                        <Col md='3'>
                                            <div style={{textAlign:'left'}}>
                                                <h3 style={{fontWeight:'800',color:'#5FA77D', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>08</h3>
                                                <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0',color:'white'}}>Kemampuan Ketelusuran</h3>
                                            </div>
                                        </Col>
                                    ):(
                                        <Col md='3'>
                                            <div style={{textAlign:'right'}}>
                                                <h3 style={{fontWeight:'800',color:'#5FA77D', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>08</h3>
                                                <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0',color:'white'}}>Kemampuan Ketelusuran</h3>
                                            </div>
                                        </Col>
                                    )}
                                    {(width<mobileThreshold)?(
                                        <Col md='9'>
                                            <div style={{marginTop:'1%',textAlign:'right'}}>
                                                <p style={{fontWeight:'400', color:'#f0f0f0',marginBottom:'1rem'}}>
                                                Kami berupaya untuk selalu menjaga ketelusuran produk kami dimana seluruh produk yang tersertifikasi berasal dari bahan-bahan yang telah disetujui dan diproduksi pada fasilitas yang memenuhi kriteria halal. Halal tracker, yang kami inisiasi, adalah salah satu langkah kami dalam mengedepankan informasi ketelusuran halal produk kami secara bertahap.
                                                </p>
                                                <Link to={{pathname: '/produksi-halal', state: { prevPath: window.location.pathname }}}>
                                                    <button>Telusuri Proses Produksi</button>
                                                </Link>
                                            </div>
                                        </Col>
                                    ):''}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section-lg section' style={{backgroundColor:'#008236', padding:'3rem 0 5rem 0'}}>
                        <div className='container'>
                            <Row>
                                <Col lg='12'>
                                    <Row>
                                        <Col md='4' lg='3' style={{position:'relative'}}>
                                            <div style={(width>=mobileThreshold)?{position:'absolute', bottom:'0'}:{}}>
                                                <h3 style={{fontWeight:'800',color:'#5FA77D', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>09</h3>
                                                <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0', color:'white'}}>Penanganan Produk</h3>
                                            </div>
                                        </Col>
                                        <Col md='8' lg='9'>
                                            <div style={{textAlign:'center'}}>
                                                <img style={{width:'100%'}} src={require('../assets/img/sierad/penanganan_1.jpg').default} alt="karkas"/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="12">
                                    <Row>
                                        <Col md='9'>
                                            <div style={{margin:'2rem 0 0 0'}}>
                                                <p style={{fontWeight:'400', textAlign:'left', color:'#f0f0f0'}}>
                                                Produk yang sudah disertifikasi tetapi terlanjur diproduksi dari bahan yang tidak disetujui dan/atau diproduksi di fasilitas yang tidak memenuhi kriteria setelah diidentifikasi dari audit internal, audit pemasok, pemeriksaan mutu produk rutin, maka tidak akan dijual ke masyarakat dan dimusnahkan sesuai dengan prosedur yang ditetapkan perusahaan.
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section-lg section' style={{backgroundColor:'white', padding:'5rem 0 0 0'}}>
                        <div className='container'>
                            <Row>
                                <Col md='6' lg='6'>
                                    <div style={{textAlign:'center'}}>
                                        <UncontrolledCarousel items={this.state.audit_internal}/>
                                    </div>
                                </Col>
                                <Col md='6' lg="6">
                                    <div>
                                        <h3 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>10</h3>
                                        <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Audit Internal</h3>
                                        <p style={{fontWeight:'400', textAlign:'left'}}>
                                        Sreeya Sewu Indonesia berkomitmen untuk melakukan verifikasi pemenuhan 11 kriteria Halal dengan dibentuknya tim auditor internal dan melakukan audit setidaknya dua kali dalam satu tahun.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section-lg section' style={{backgroundColor:'#fff', padding:'3rem 0'}}>
                        <div className='container'>
                            <Row>
                                <Col lg='12'>
                                    <Row>
                                        <Col md='4' lg='3' style={{position:'relative'}}>
                                            <div style={(width>=mobileThreshold)?{position:'absolute', bottom:'0'}:{}}>
                                                <h3 style={{fontWeight:'800',color:'#FCEFD9', margin:'0 0 1rem 0', fontSize:'3.5rem'}}>11</h3>
                                                <h3 style={{fontWeight:'800',margin:'0 0 1.3rem 0'}}>Kaji Ulang Management</h3>
                                            </div>
                                        </Col>
                                        <Col md='8' lg='9'>
                                            <div style={{textAlign:'center'}}>
                                                <img style={{width:'100%'}} src={require('../assets/img/sierad/kaji-management.jpeg').default} alt="kaji-ulang-manajemen"/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="12">
                                    <Row>
                                        <Col md='9'>
                                            <div style={{margin:'2rem 0 0 0'}}>
                                                <p style={{fontWeight:'400', textAlign:'left'}}>
                                                Sistem Jaminan Halal (SJH) adalah sistem manajemen terintegrasi yang disusun, diterapkan dan dipelihara untuk mengatur bahan, proses produksi, produk, sumber daya manusia dan prosedur dalam rangka menjaga kesinambungan proses produksi halal sesuai dengan persyaratan LPPOM MUI. Sebagai perusahaan yang mengedepankan produk halal dan bermutu, Sreeya berkomitmen untuk selalu memenuhi kriteria halal.
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <FooterSubscribe />
                    <FooterDark />
                </div>
            </div>
        )
    }
}

// export default withRouter((App));
const mapStateToProps = (state) => {
    const {language} = state;
    return {
        language
    };
  };
export default withRouter(connect(mapStateToProps)(App));
