/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";
// import SieradLogo from '../../assets/img/logo-sieradproduce.png';
import SreeyaLogo from '../../../../assets/img/sreeya_warna.png';
// import ID from '../../assets/img/flags/ID.png';
// import UK from '../../assets/img/flags/GB.png';

function IndexNavbar(lang) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [hideNavbar, setHideNavbar] = React.useState(false)

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const checkUrl = (page) => {
    let url = window.location.href;
    if (url.indexOf(page) > -1) {
      return true
    } else {
      return false
    }
  }

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    if (checkUrl('/produksi-halal')) return setHideNavbar(true)
    if (!checkUrl('/sistem-jaminan-halal') && !checkUrl('/old') && !checkUrl('/hubungi-kami')) {
      return setNavbarColor("");
    } else {
      window.addEventListener("scroll", updateNavbarColor);
    }

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      { !hideNavbar &&
        <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
          <Container>
            <div className="navbar-translate">
              {/* <NavbarBrand
            data-placement="bottom"
            href="/index"
            target="_blank"
            title="Coded by Creative Tim"
          >
            Paper Kit React
          </NavbarBrand> */}
              <Nav navbar>
                <NavItem>
                  <Link to={{ pathname: '/produksi-halal', state: { prevPath: window.location.pathname } }} className='nav-link' style={{ margin: '0', padding: '0', marginLeft: '1rem' }}>
                    <img src={SreeyaLogo} alt='logo Sierad' style={{ maxHeight: '3rem' }} />
                    {/* <span style={{color:"white",fontSize:'20px'}}>Sierad Produce</span> */}
                  </Link>
                </NavItem>
              </Nav>
              <button
                aria-expanded={navbarCollapse}
                className={classnames("navbar-toggler navbar-toggler", {
                  toggled: navbarCollapse
                })}
                onClick={toggleNavbarCollapse}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={navbarCollapse}
            >
              <Nav navbar>
                {/* <NavItem>
              <Link to={'/home'} className='nav-link' style={{color:(checkUrl('home'))?'#ec971f':'#66615b', borderRadius:'20px'}}>
                {(lang.lang === "eng")?"Home":"Utama"}
              </Link>
            </NavItem> */}
                <NavItem>
                  <Link to={{ pathname: '/produksi-halal', state: { prevPath: window.location.pathname } }} className='nav-link' style={{ color: (checkUrl('produksi-halal')) ? '#ec971f' : '#66615b', borderRadius: '20px' }}>
                    {(lang.lang === "eng") ? "Halal Production" : "Produksi Halal"}
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link to={{ pathname: '/halal-tracker', state: { prevPath: window.location.pathname } }} className='nav-link' style={{ color: (checkUrl('halal-blockchain')) ? '#EA9700' : '#929292', borderRadius: '20px' }}>
                    {(lang.lang === "eng") ? "Halal Tracker" : "Halal Tracker"}
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link to={{ pathname: '/sistem-jaminan-halal', state: { prevPath: window.location.pathname } }} className='nav-link' style={{ color: (checkUrl('sistem-jaminan-halal')) ? '#EA9700' : '#929292', borderRadius: '20px' }}>
                    {(lang.lang === "eng") ? "Halal System" : "Sistem Jaminan Halal"}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to={{ pathname: '/faq', state: { prevPath: window.location.pathname } }} className='nav-link' style={{ color: (checkUrl('faq')) ? '#EA9700' : '#929292', borderRadius: '20px' }}>
                    {(lang.lang === "eng") ? "FAQ" : "FAQ"}
                  </Link>
                </NavItem>
                <NavItem>
                  <a href='https://www.sreeyasewu.com/en/home' className='nav-link' style={{ color: '#929292', borderRadius: '20px' }}>
                    {(lang.lang === "eng") ? "Tentang Kami" : "Tentang Kami"}
                  </a>
                </NavItem>
                <NavItem>
                  <Link to={{ pathname: '/hubungi-kami', state: { prevPath: window.location.pathname } }} className='nav-link' style={{ color: (checkUrl('hubungi-kami')) ? '#EA9700' : '#929292', borderRadius: '20px' }}>
                    {(lang.lang === "eng") ? "Contact Us" : "Hubungi Kami"}
                  </Link>
                </NavItem>
                {/* <NavItem>
              <UncontrolledDropdown>
                <DropdownToggle caret className="button-language nav-link">
                  {(lang.lang === "eng")?<img src={UK} alt="GB"/>:<img src={ID} alt="ID"/>}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>{(lang.lang === "eng")?"Language":"Bahasa"}</DropdownItem>
                  <DropdownItem onClick={()=>{lang.handleLang('eng')}}><img src={UK} alt="GB"/> {(lang.lang === "eng")?"English":"Inggris"}</DropdownItem>
                  <DropdownItem onClick={()=>{lang.handleLang('indo')}}><img src={ID} alt="ID"/> {(lang.lang === "eng")?"Indonesian":"Indonesia"}</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem> */}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      }
    </>
  );
}

export default withRouter(IndexNavbar);
