import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import { createBrowserHistory } from "history";
// import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './redux/store/index';
import './assets/css/global-style.css'

// import AdminLayout from "./layouts/Admin.jsx";

// const hist = createBrowserHistory();
import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// import "./Dashboard/assets/css/custom.css";
// import "./Dashboard/assets/css/landing-page-custom.css";
import "react-datepicker/dist/react-datepicker.css";

window.store = store;

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
