import React, {Component} from 'react';
import {withRouter} from 'react-router';
import SearchHeader from "../components/Headers/SearchHeader.js";
// import DemoFooter from "../components/Footers/DemoFooter.js";
import FooterDark from "../components/Footers/FooterDark";
import FooterSubscribe from "../components/Footers/FooterSubscribe";
import { Row,Col, Button,UncontrolledTooltip,UncontrolledCarousel,Modal, ModalBody, ModalFooter } from "reactstrap";
import {connect} from 'react-redux';
// import Zoom from 'react-reveal/Zoom';
// import Fade from 'react-reveal/Fade';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {ProsesActions} from "../../../redux/actions";
// import { EditorState, convertToRaw,ContentState, convertFromHTML } from 'draft-js';
    
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProcess:'',
            process:[
                // {
                //     title:'Antemortem',
                //     description:'Antemortem merupakan proses pemeriksaan setiap ayam yang datang terutama ayam yang mati saat tiba di RPA. Dokter hewan akan memeriksa dan mengidentifikasi penyebab kematian ayam. Jika memang terindikasi penyakit yang tidak layak konsumsi untuk manusia maka seluruh ayam di truk tersebut akan dikembalikan ke peternakan dan ayam yang mati akan dimusnahkan.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'08.30 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/inspeksi.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total ayam yang ditolak',
                //             dataType:'number',
                //             data:320,
                //             unit:'ekor',
                //         },
                //         {
                //             title:'Total ayam mati dalam perjalanan ',
                //             dataType:'number',
                //             data:100,
                //             unit:'ekor',
                //         },
                //         {
                //             title:'Penyebab ayam dikembalikan',
                //             dataType:'text',
                //             data:'defect',
                //         },
                //     ],
                // },{
                //     title:'Penimbangan Ayam Hidup',
                //     description:'Ayam yang dinyatakan lolos oleh dokter hewan dari proses sebelumnya akan ditimbang untuk disesuaikan lagi dengan laporan pengiriman dari peternakan.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'08.50 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['animal_welfare'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/inspeksi.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total jumlah ayam yang diterima',
                //             dataType:'number',
                //             data:20000,
                //             unit:'ekor',
                //         },
                //         {
                //             title:'Total berat ayam yang diterima',
                //             dataType:'number',
                //             data:31000,
                //             unit:'kg',
                //         },
                //     ],
                // },{
                //     title:'Pengistirahatan',
                //     description:'Pengistirahatan ayam dilakukan untuk membuat ayam rileks dan tidak stres setelah perjalanan panjang, dalam proses ini ayam diangin-anginkan menggunakan kipas angin',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.00 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['animal_welfare'],
                //     image:[
                //         {
                //             src:require('../assets/img/slaughter-house.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total jumlah ayam yang mati saat istirahat',
                //             dataType:'number',
                //             data:0,
                //             unit:'ekor',
                //         },
                //     ],
                // },{
                //     title:'Penggantungan Ayam Hidup',
                //     description:'Setiap ayam yang sudah diistirahatkan akan digantung di mesin sackel ayam dan dihitung kembali jumlah ayam yang digantung. Dan dipastikan ayam yang digantung adalah ayam yang hidup, jika ayam mati maka tidak akan digantung dan akan dimusnahkan.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.15 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['animal_welfare'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/gantung-ayam.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total jumlah ayam yang digantung',
                //             dataType:'number',
                //             data:20000,
                //             unit:'ekor',
                //         },
                //     ],
                // },{
                //     title:'Proses Pemingsanan (Stunning)',
                //     description:'Sesuai ketentuan standar halal dari MUI untuk tidak menyembelih ayam dalam keadaan sadar, maka dilakukan penyetruman terhadap ayam untuk membuat ayam tidak sadarkan diri. Voltase untuk penyetruman juga sesuai standar dari MUI.',
                //     knowledge_point:'Stunning adalah suatu cara melemahkan hewan melalui pemingsanan sebelum pelaksanaan penyembelihan agar pada waktu disembelih hewan tidak banyak bergerak. (FATWA MAJELIS ULAMA INDONESIA Nomor 12 Tahun 2009 Tentang STANDAR SERTIFIKASI PENYEMBELIHAN HALAL)',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.16 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/bersih-jeroan.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Penyembelihan Halal',
                //     description:'Penyembelihan ayam dilakukan oleh orang-orang yang sudah bersertifikasi MUI untuk melakukan penyembelihan ayam. Dan setiap ayam yang akan masuk ke proses selanjutnya dipastikan sudah mati.',
                //     knowledge_point:'Dasar legalitas ada pada sejumlah aturan seperti pada HAS 23103 : Guideline of Halal Assurance System Criteria on Slaughterhouses (2012), SKKNI No 196 tahun 2014 : Penetapan Standar Kompetensi Kerja Nasional Indonesia Kategori Pertanian, Kehutanan Dan Perikanan Golongan Pokok Jasa Penunjang Peternakan Bidang Penyembelihan Hewan Halal  dan SNI 99002:2016 tentang pemotongan halal pada unggas. Persyaratan Juleha (penyembelih / juru sembelih ) adalah seperti Islam, dewasa, sehat jasmani dan rohani. Peralatan yang dipakai: tajam, mampu melukai sehingga darah mengalir, tidak terbuat dari kuku dan tulang.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.21 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/penyembelihan.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Penyembelih',
                //             dataType:'penyembelih',
                //             data:[
                //                 {
                //                     name:'Juan',
                //                     cerificate:'MUI238363283',
                //                     image:require('../assets/img/sierad/penyembelih.png'),
                //                 },
                //                 {
                //                     name:'Djafar',
                //                     cerificate:'MUI238345534',
                //                     image:require('../assets/img/sierad/penyembelih.png'),
                //                 },
                //                 {
                //                     name:'Harris',
                //                     cerificate:'MUI238332434',
                //                     image:require('../assets/img/sierad/penyembelih.png'),
                //                 },
                //                 {
                //                     name:'Harris',
                //                     cerificate:'MUI238332434',
                //                     image:require('../assets/img/sierad/penyembelih.png'),
                //                 }
                //             ],
                //         },
                //     ],
                // },{
                //     title:'Penirisan Darah',
                //     description:'Penirisan darah dilakukan untuk membersihkan bakteri yang dapat mengkontaminasi ayam supaya tidak cepat busuk. darah limbah yang dihasilkan ini akan diolah di mesin pengolahan limbah agar tidak mencemari lingkungan.',
                //     knowledge_point:'Penyembelihan dilakukan dengan mengalirkan darah melalui pemotongan saluran makanan (mari’/esophagus), saluran pernafasan/tenggorokan (hulqum/trachea), dan dua pembuluh darah (wadajain/vena jugularis dan arteri carotids). (FATWA MAJELIS ULAMA INDONESIA Nomor 12 Tahun 2009 Tentang STANDAR SERTIFIKASI PENYEMBELIHAN HALAL)',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.22 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/cabut-bulu.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Pencucian dengan Air Mendidih (Scalding)',
                //     description:'Proses pencucian ini untuk membersihkan ayam dari darah sekaligus dengan air mendidih akan memudahkan untuk proses pencabutan bulu.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.30 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/cabut-bulu.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Pencabutan Bulu',
                //     description:'Proses untuk memisahkan bulu dari ayam dengan menggunakan mesin berjalan. Bulu-bulu limbah dari ayam akan diproses di mesih pengolahan limbah bulu ayam dan akan diproses untuk dijadikan pakan ayam',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.35 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:[],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/cabut-bulu.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Pemotongan Kepala Leher',
                //     description:'Tahap ini dilakukan untuk pemotongan kepala ayam.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.43 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/potong.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total berat kepala ayam',
                //             dataType:'number',
                //             data:3840,
                //             unit:'kg',
                //         },
                //     ],
                // },{
                //     title:'Pemotongan Ceker',
                //     description:'Proses untuk memisahakn ceker dari badan ayam',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.44 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/potong.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total berat ceker ayam',
                //             dataType:'number',
                //             data:3840,
                //             unit:'kg',
                //         },
                //     ],
                // },{
                //     title:'Penggantungan Karkas ke Shackle',
                //     description:'Pada proses ini ayam yang diturunkan dari mesin sackel sebelumnya akan digantung lagi ke mesin sackel pada ruangan proses selanjutnya.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.45 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/slaughter-house.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Pembukaan Kloaka  & Pengeluaran Jeroan',
                //     description:'Pada proses ini seluruh ayam akan dibelah untuk dikeluarkan jeroan dan dilakukan pembersihan kotoran ayam.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.47 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/pembersihan-jeroan.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total berat jeroan',
                //             dataType:'number',
                //             data:50374,
                //             unit:'kg',
                //         },
                //     ],
                // },{
                //     title:'Postmortem',
                //     description:'Pada proses ini ayam disortir kembali kelayakan konsumsinya dari keadaan ayam, jika ada yam yang memar maka bagian memar akan dipotong agar tidak mencemari chilling tank.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.50 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/slaughter-house.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Penyemprotan Air sebelum Karkas masuk area bersih',
                //     description:'',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.55 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/slaughter-house.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Pencucian Karkas dalam Tangki Dingin (Cleaning & Cooling in Chilling Tank)',
                //     description:'Ayam yang sudah menjadi karkas akan dicuci kembali dari kotoran yang masih menempel lalu memasuki ruangan steril dengan suhu ruangan terus dijaga. Lalu ayam akan didinginkan dengan dimasukkan ke Chilling Tank dengan suhu air < 4 derajat Celcius',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'09.52 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/inspeksi-chilling.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[],
                // },{
                //     title:'Grading Karkas & Penimbangan Karkas',
                //     description:'Proses pemisahan kualitas dari ayam dan pemisahan kelompok berat ayam.',
                //     knowledge_point:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                //     location:'RPA Sierad, Parung, Bogor',
                //     locationType:'RPA',
                //     waktu:'10.00 WIB',
                //     tanggal:'16 Februari 2020',
                //     blockchain_address:'0x85c4633BeBB25935D314AfD12abE647De17F2cdD',
                //     blockchain_relation:'16-02-2020',
                //     processStamp:['halal','animal_welfare','critical_point'],
                //     image:[
                //         {
                //             src:require('../assets/img/sierad/inspeksi-ayam-chilling.jpg'),
                //             altText:'qwq',
                //         },
                //     ],
                //     data:[
                //         {
                //             title:'Total jumlah ayam karkas',
                //             dataType:'number',
                //             data:19374,
                //             unit:'ekor',
                //         },
                //         {
                //             title:'Total berat ayam kasrkas',
                //             dataType:'number',
                //             data:25948,
                //             unit:'kg',
                //         },
                //     ],
                // },
            ],
            search:'',     
            loading:false,        
            modal:false,
            selectedImage:'',                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
        }
    }
    ShowProcess=(selected)=>{
        if(selected===this.state.selectedProcess){
            this.setState({
                selectedProcess:""
            })
        } else {
            this.setState({
                selectedProcess:selected
            })
        }
        // var node = document.getElementById("view-process-"+selected);
        var yourHeight = 100;
        if(selected > this.state.selectedProcess){
            yourHeight = 160;
        }
        // node.scrollIntoView(true);
        window.setTimeout(()=>{
            var scrolledY = window.scrollY;
        
            if(scrolledY){
                window.scroll(0, scrolledY - yourHeight);
            }
        }, 700)
    }
    handleChange=(event)=>{
        this.setState({
            search:event.target.value
        })
    }
    componentWillMount(){
        this.getParameter();
    }

    getParameter=()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id')
        if(id){
            this.setState({
                search:id
            })
            this.handleSubmitSearch(id);
        } else {
            this.getListProses();
        }
    }   

    getListProses= async ()=>{
        this.setState({
            loading:true
        })
        const {dispatch} = this.props;
        let response = await dispatch(ProsesActions.getListJustProses());
        if(response){
            if(!response.data.error){
                let data = response.data.dataLocal;
                data.sort((a, b) => (a.id > b.id) ? 1 : -1)
                for(let i=0;i<data.length;i++){
                    let temp = [];
                    for(let j=0;j<data[i].processImages.length;j++){
                        temp.push({
                            // src:'https://lh3.googleusercontent.com/u/0/d/'+data[i].processImages[j].src+'=w1366-h657-iv1',
                            src:data[i].processImages[j].src,
                            altText:data[i].processImages[j].altText,
                            caption:''
                        })
                    }
                    data[i].processImages = temp;
                }
                this.setState({
                    process:data,
                    loading:false
                })
            }else{
                this.setState({
                    process:[],
                    loading:false
                })
            }
        } else {
            this.setState({
                process:[],
                loading:false
            })
        }
    }

    handleSubmitSearch=async(key)=>{
        this.setState({
            loading:true
        })
        let search = key;
        // const {search} = this.state;
        if(search === "null"){
            search = this.state.search
        }
        const {dispatch} = this.props;
        let response = await dispatch(ProsesActions.getListProses(search));
        if(response){
            if(!response.data.error){
                let data = response.data.dataLocal;
                data.sort((a, b) => (a.id > b.id) ? 1 : -1)
                for(let i=0;i<data.length;i++){
                    let temp = [];
                    for(let j=0;j<data[i].processImages.length;j++){
                        temp.push({
                            // src:'https://lh3.googleusercontent.com/u/0/d/'+data[i].processImages[j].src+'=w1366-h657-iv1',
                            src:data[i].processImages[j].src,
                            altText:data[i].processImages[j].altText,
                            caption:''
                        })
                    }
                    data[i].processImages = temp;
                }
                this.setState({
                    process:data,
                    loading:false
                })
            }else{
                this.setState({
                    process:[],
                    loading:false
                })
            }
        }else{
            this.setState({
                process:[],
                loading:false
            })
        }
    }

    dateFormatting = (date) =>{
        const today = new Date(date)
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()
        let monthName = ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember']
        const finalDate = day+" "+monthName[month]+" "+year
        return finalDate
    }

    dateIDFormatting = (date) =>{
        const today = new Date(date)
        const year = today.getFullYear()
        const month = ("0" + (today.getMonth() + 1)).slice(-2)
        const day = ("0" + today.getDate()).slice(-2)
        const finalDate = year+""+month+""+day
        return finalDate
    }

    toggle=(image)=>{
        this.setState({
            modal:!this.state.modal,
            selectedImage:image,
        })
    }
    componentDidUpdate(prevProps) {
        if(prevProps.location.state){
            if (this.props.location.pathname !== prevProps.location.state.prevPath) {
            window.scrollTo(0, 0)
            }
        }
    }
    render() {
        return (
            <div>
                {/* <IndexHeader /> */}
                <div className="main">
                    <SearchHeader handleSubmitSearch={(e)=>this.handleSubmitSearch(e)} handleChange={(e)=>this.handleChange(e)} search={this.state.search}/>
                    <section className='section-lg section section-process'>
                        <div className='container'>
                            <div style={{textAlign:'center'}}>
                                <h3 style={{fontWeight:'800',margin:'1rem 0'}}>Proses Produksi Halal</h3>
                                <p style={{fontWeight:'400'}}>
                                Sebagai perusahaan yang mengedepankan produk halal dan bermutu, Sreeya berkomitmen untuk selalu memenuhi kriteria halal sesuai dengan Sistem Jaminan Halal yang berlaku.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className='section-lg section section-process' id="view-process">
                        <div className='container'>
                            <div style={{textAlign:'center'}}>
                                {(this.state.loading)?
                                    <div style={{margin:'5rem 0'}}>
                                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div>
                                :
                                (this.state.process.length>0)?
                                <VerticalTimeline layout={ '1-column' }>
                                    {this.state.process.map((proses,idx)=>{
                                        return(
                                            <VerticalTimelineElement
                                                icon={<div>{idx+1}</div>}
                                                className={(idx === this.state.selectedProcess)?'active-process':'not-active-process'}
                                                id={"view-process-"+idx}
                                                key={"view-process-"+idx}
                                            >
                                                <div className='public-process'>
                                                    <div className="public-process-title">
                                                        <h3 style={{marginTop:'0'}} onClick={()=>this.ShowProcess(idx)}><a href={"#view-process-"+idx}>{proses.processName}</a></h3>
                                                        {/* <h3 onClick={()=>this.ShowProcess(idx)}>{proses.title}</h3> */}
                                                        {/* <div>
                                                            <img id={"bottom1"} style={{width:'35px', cursor:'pointer'}} alt="block" src={require('../assets/img/sierad/block 1.png')}/>
                                                            <UncontrolledTooltip placement="bottom" target={"bottom1"} delay={0}>
                                                                <div className="public-process-tooltip public-process-tooltip-blockchain">
                                                                    Block address: 0x85c4633BeBB25935D314AfD12abE647De17F2cdD <br/><br/>
                                                                    Relasi : M11602200002
                                                                </div>
                                                            </UncontrolledTooltip>
                                                        </div> */}
                                                    </div>
                                                    {(proses.processStamps && proses.processStamps.length >0)?
                                                    <div className="public-process-bedge">
                                                        {proses.processStamps.map((stamp,index)=>{
                                                            if(stamp === "halal"){
                                                                return(
                                                                    <div key={index} style={{display:'inline-block'}}>
                                                                        <img id={"halal-"+idx} src={require('../assets/img/sierad/bedge-halal.png').default} alt="bedge"/>
                                                                        <UncontrolledTooltip placement="bottom" target={"halal-"+idx} delay={0}>
                                                                            <div className="public-process-tooltip public-process-tooltip-halal">
                                                                                <img src={require('../assets/img/sierad/icon-halal.png').default} alt="icon-halal"/><br/><br/>
                                                                                Proses ini merupakan titik kritikal halal.
                                                                            </div>
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                )
                                                            } else if(stamp==="animal_welfare"){
                                                                return(
                                                                    <div key={index} style={{display:'inline-block'}}>
                                                                        <img id={"critical-"+idx} src={require('../assets/img/sierad/bedge-critical.png').default} alt="bedge"/>
                                                                        <UncontrolledTooltip placement="bottom" target={"critical-"+idx} delay={0}>
                                                                            <div className="public-process-tooltip public-process-tooltip-critical">
                                                                                <img src={require('../assets/img/sierad/icon-critical.png').default} alt="icon-critical"/><br/><br/>
                                                                                Proses ini merupakan titik kritis penentu keberhasilan produk.
                                                                            </div>
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return(
                                                                    <div key={index} style={{display:'inline-block'}}>
                                                                        <img id={"welfare-"+idx} src={require('../assets/img/sierad/bedge-walfare.png').default} alt="bedge"/>
                                                                        <UncontrolledTooltip placement="bottom" target={"welfare-"+idx} delay={0}>
                                                                            <div className="public-process-tooltip public-process-tooltip-welfare">
                                                                                <img src={require('../assets/img/sierad/icon-welfare.png').default} alt="icon-welfare"/><br/><br/>
                                                                                Proses ini merupakan poin penting untuk menjamin kelayakan perlakuan terhadap hewan.
                                                                            </div>
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                    :''}
                                                    <div className="public-process-content">
                                                        {(proses.date)?
                                                        <div className="public-process-content-idtanggal">
                                                            <Row>
                                                                <Col md='6'>
                                                                    <p>Nomor ID: {proses.idBatch}</p>
                                                                </Col>
                                                                <Col md='6'>
                                                                    <p>Tanggal: {this.dateFormatting(proses.date)}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        :''}
                                                        {(proses.data.length>0)?
                                                        <div className="public-process-content-value">
                                                            
                                                            {(proses.processCodename !== "slaughter")?
                                                            (
                                                                <Row>
                                                                    {proses.data.map((list,idx)=>{
                                                                        if(list.dataType === "text"){
                                                                            return (
                                                                                <Col sm="6" md='6' key={idx}>
                                                                                    <div className="public-process-content-value-box">
                                                                                        <p className="value">{list.value}</p>
                                                                                        <p className="key">{list.dataName}</p>
                                                                                    </div>
                                                                                </Col>
                                                                            )
                                                                        } else if(list.dataType === "number"){
                                                                            return (
                                                                                <Col sm="6" md='6' key={idx}>
                                                                                    <div className="public-process-content-value-box">
                                                                                        <p className="value">{Math.round(list.value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} {list.unit}</p>
                                                                                        <p className="key">{list.dataName}</p>
                                                                                    </div>
                                                                                </Col>
                                                                            )
                                                                        } else {
                                                                            return null
                                                                        }
                                                                    })}
                                                                </Row>
                                                            )
                                                            : 
                                                            (
                                                                <Row>
                                                                    {proses.data.map((list,idx)=>{
                                                                        if(idx === 0){
                                                                            if(list.dataType === "text"){
                                                                                return (
                                                                                    <Col sm="6" md='6' key={idx}>
                                                                                        <div className="public-process-content-value-box">
                                                                                            <p className="value">{list.value}</p>
                                                                                            <p className="key">{list.dataName}</p>
                                                                                        </div>
                                                                                    </Col>
                                                                                )
                                                                            } else if(list.dataType === "number"){
                                                                                return (
                                                                                    <Col sm="6" md='6' key={idx}>
                                                                                        <div className="public-process-content-value-box">
                                                                                            <p className="value">{Math.round(list.value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} {list.unit}</p>
                                                                                            <p className="key">{list.dataName}</p>
                                                                                        </div>
                                                                                    </Col>
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        } else {
                                                                            return null
                                                                        }
                                                                    })}
                                                                    <div key={idx} style={{textAlign:'left'}}>
                                                                        <br/>
                                                                        <h5 style={{color:'#008236',fontSize:'16px', fontWeight:'bold', marginLeft:'1rem'}}>Sertifikat penyembelih</h5>
                                                                        <Row>
                                                                            {proses.data.map((list,idx)=>{
                                                                                if(idx !== 0){
                                                                                    return (
                                                                                        <Col md="3" sm="6" key={idx} style={{textAlign:'center',marginTop:'1rem'}}>
                                                                                            {(list.photo)?
                                                                                            // <img className='img-thumbnail' style={{width:'75%'}} src={'https://lh3.googleusercontent.com/u/0/d/'+list.photo+'=w1366-h657-iv1'} alt={"photo-"+idx}/>
                                                                                            <img className='img-thumbnail' style={{width:'75%'}} src={list.photo} alt={"photo-"+idx}/>
                                                                                            :
                                                                                            <img style={{width:'100%'}} src={require('../assets/img/default-avatar.png').default} alt="default"/>
                                                                                            }
                                                                                            <br/>
                                                                                            <span style={{color:'#008236',fontSize:'16px', fontWeight:'bold'}}>
                                                                                                {list.name}
                                                                                            </span><br/>
                                                                                            <span style={{color:'#828282',fontSize:'14px', fontWeight:'bold'}}>
                                                                                                No. Sertifikat
                                                                                            </span><br/>
                                                                                            <span onClick={()=>this.toggle(list.sertifikatImage)} className='sertifikat-penyembelih' style={{color:'#828282',fontSize:'14px', fontWeight:'bold'}}>
                                                                                                {list.noSertifikat}
                                                                                            </span>
                                                                                        </Col>
                                                                                    );
                                                                                } else {
                                                                                    return null
                                                                                }
                                                                            })}
                                                                        </Row>
                                                                    </div>
                                                                </Row>
                                                            )
                                                            }
                                                        </div>
                                                        :''}
                                                        {(proses.idBatch)?
                                                        <div className="public-process-content-button">
                                                            <div>
                                                                <a style={{margin:'0.8rem 0'}} target="_blank" href={"https://blockexplorer.sieradproduce.com/traces?address="+proses.blockchainAddress+"&version="+this.dateIDFormatting(proses.date)} >Telusuri dalam Blockchain</a>
                                                            </div>
                                                        </div>
                                                        :''}
                                                        {(proses.processImages && proses.processImages.length >0)?
                                                        <div className="public-process-content-image">
                                                            <UncontrolledCarousel items={proses.processImages}/>
                                                        </div>
                                                        :''}
                                                        <div className="public-process-content-description" style={{color:'#888', fontWeight:'400'}}>
                                                            <div dangerouslySetInnerHTML={{ __html: proses.description }} />
                                                            {/* <p style={{fontWeight:'400'}}>
                                                                {proses.description}
                                                            </p> */}
                                                        </div>
                                                        {(proses.knowledgePoint)?
                                                        (!proses.knowledgePoint.includes("<p></p>"))?
                                                        <div className="public-process-content-keypoint" style={{color:'#888', fontWeight:'400'}}>
                                                            <h3>Apa Kamu Tahu?</h3>
                                                            <div dangerouslySetInnerHTML={{ __html: proses.knowledgePoint }} />
                                                            {/* <p style={{fontWeight:'400'}}>
                                                                {proses.knowledgePoint}
                                                            </p> */}
                                                        </div>
                                                        :''
                                                        :''}
                                                    </div>
                                                </div>
                                            </VerticalTimelineElement>
                                        )
                                    })}
                                </VerticalTimeline>
                                :
                                <div style={{margin:'5rem 0'}}><p style={{textAlign:'center', fontWeight:'600'}}>Tidak Ada Proses Produksi</p></div>
                                
                                }
                            </div>
                        </div>
                    </section>
                <FooterSubscribe />
                <FooterDark />
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalBody style={{padding:'1rem'}}>
                    {/* <img style={{width:'100%'}} src={'https://lh3.googleusercontent.com/u/0/d/'+this.state.selectedImage+'=w1366-h657-iv1'} alt={"sertifikat-penyembelih"}/> */}
                    <img style={{width:'100%'}} src={this.state.selectedImage} alt={"sertifikat-penyembelih"}/>
					</ModalBody>
					<ModalFooter style={{padding:'1rem'}}>
					<Button color="secondary" onClick={this.toggle}>Keluar</Button>
					</ModalFooter>
				</Modal>
            </div>
        )
    }
}

// export default withRouter((App));
const mapStateToProps = (state) => {
    const {language} = state;
    return {
        language
    };
  };
export default withRouter(connect(mapStateToProps)(App));
