import React from "react";
import {connect} from 'react-redux';
import DragSortableList from 'react-drag-sortable';
import {Button} from 'reactstrap';
import {Link} from "react-router-dom";

// reactstrap components

import {FaqActions,LoadingActions} from "../../../redux/actions";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
        }
    }
    onSort = async (sortedList) => {
        let newList = [];
        for(let i=0;i<sortedList.length;i++){
            let temp = {id:parseInt(sortedList[i].content.key),number:sortedList[i].rank+1};
            newList.push(temp)
        }
        this.setState({
            list:sortedList,
        })
        this.sortFaq(newList)
    }
    sortFaq = async(payload) => {
        const {dispatch} = this.props;
        await dispatch(LoadingActions.changeLoading(true));
        let response = await dispatch(FaqActions.FaqOrder(payload));
        if(response){
            await dispatch(LoadingActions.changeLoading(false));
        } else {
            await dispatch(LoadingActions.changeLoading(false));
        }
    }
    componentWillMount(){
        this.getListFaq();
    }
    getListFaq= async ()=>{
        const {dispatch} = this.props;
        await dispatch(LoadingActions.changeLoading(true));
        let response = await dispatch(FaqActions.getListFaq());
        if(response){
            if(!response.data.error){
                let data = response.data.faqs;
                data.sort((a, b) => (a.number > b.number) ? 1 : -1)
                let newSet = [];
                for(let i=0;i<data.length;i++){
                    let temp = (
                        <div className="listFaq" key={data[i].id}>
                            <div style={{flex:1}}>{data[i].title} </div>
                            <div>
                                <Link to={"/cms/faq/edit/"+data[i].id}><i className='fas fa-edit'></i></Link>
                            </div>
                        </div>
                    );
                    newSet.push({
                        content:temp
                    })
                    this.setState({
                        list:newSet
                    })
                }
                await dispatch(LoadingActions.changeLoading(false));
            } else {
                this.setState({
                    list:[]
                })
                await dispatch(LoadingActions.changeLoading(false));
            }
        } else {
            this.setState({
                list:[]
            })
            await dispatch(LoadingActions.changeLoading(false));
        }
    }
    render(){
        return (
            <div>
                <div className="cms-container-content">
                    <div style={{display:'flex',marginBottom:'2rem'}}>
                        <h4 style={{flex:'1'}}>Pengaturan FAQ</h4>
                        <Link to="/cms/faq/add"><Button color="primary" style={{fontSize:'14px'}}>+ Tambah FAQ</Button></Link>
                    </div>
                    <div>
                        <span style={{color:'#888',fontSize:'12px'}}>*Drag and drop to order the FAQ</span>
                    </div>
                    <DragSortableList items={this.state.list} moveTransitionDuration={0.3} onSort={this.onSort} type="vertical"/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {language,loading} = state;
    return {
      language,
      loading
    };
};

export default connect(mapStateToProps)(Index);
