// import { api } from '../api/';
import * as types from '../constants/action-types';
// import { history } from '../helpers';

const changeLoading = (val) => {
  return (dispatch) => {
    try {
      dispatch(loading(types.LOADING, val));
    } catch (error) {
      return error;
    }   
  }
}

export const loading = (type, data) => {
  return {
    type: type,
    data: data,
  }
}

export const LoadingActions = {
    changeLoading,
}
