import React, { Component } from 'react'

export default class Restaurantv2 extends Component {

    // componentWillUnmount() {
    //     window.location.replace("http://stackoverflow.com");
    // }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const utm_source = urlParams.get('utm_source')
        const utm_medium = urlParams.get('utm_medium')
        const utm_campaign = urlParams.get('utm_campaign')
        window.location.replace(`/halal-tracker?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`);
    }
    render() {
        return (
            <div>
            </div>
        )
    }
}
