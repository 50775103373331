import { api } from '../../api/';
// import * as types from '../constants/action-types';
// import { history } from '../helpers';

const sendMessage = (payload) => {
  return async (dispatch) => {
      try {
          const apiEndpoint = 'send-enquiry/';
          const response = await api.post(apiEndpoint,payload);
          return response;
      } catch (error) {
            return error;
      }
  };
};


export const auths = (type, data) => {
  return {
    type: type,
    data: data,
  }
}

export const ContactUsActions = {
    sendMessage,
}
