import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ContentProcess from "../containers/cms/views/content-process";
import ContentFaq from "../containers/cms/views/content-faq";
import ContentFaqAdd from "../containers/cms/views/content-faq-add";
import ContentFaqEdit from "../containers/cms/views/content-faq-edit";
import ContentTim from "../containers/cms/views/content-tim";
import ContentSertifikat from "../containers/cms/views/content-sertifikat";

// import {history} from './helpers';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Routes = () => (
        <Switch>
            <Route path="/cms/tim" component={ContentTim}/>
            <Route path="/cms/sertifikat" component={ContentSertifikat}/>
            <Route path="/cms/faq/add" component={ContentFaqAdd}/>
            <Route path="/cms/faq/edit" component={ContentFaqEdit}/>
            <Route path="/cms/faq" component={ContentFaq}/>
            <Route path="/cms" component={ContentProcess}/>
            <Redirect from='/' to='/home' />
        </Switch>
);

export default Routes;