import React from "react";
import {connect} from 'react-redux';
import Footer from "../components/Footer/Footer";
import Route from "../../../Route/route-cms";
import {withRouter} from 'react-router';

import {Nav} from 'reactstrap';
import {NavLink} from "react-router-dom";

import '../assets/css/custom.css'

import {LanguageActions} from "../../../redux/actions";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang:'indo',
      sidebarToggle:true,
      activeMenu:undefined
    }
  }

  handleLang = (lang) => {
    const {dispatch} = this.props;
    dispatch(LanguageActions.changeLanguage(lang));
    this.setState({
      lang:lang
    })
  }

  handleToggle = () => {
    this.setState({
      sidebarToggle:!this.state.sidebarToggle
    })
  }

  getPage=()=>{
    let endpoint = window.location.pathname.split('/');
    this.setState({
      activeMenu:endpoint[2]
    })
  }

  componentWillMount(){
    this.getPage();
  }

  handleLogout=()=>{
    localStorage.clear();
    this.props.history.push('/login')
  }

  render(){
    return (
      <>
        <div style={{
            position: 'relative',
            width:'100%'
        }}>
            <div className={(this.state.sidebarToggle)?'cms-sidebar':'cms-sidebar action'}>
              <div className="logo">
                <a
                  href="/cms"
                  className="simple-text logo-normal"
                >
                  Content Management System
                </a>
              </div>
              <div className="cms-sidebar-wrapper" ref={this.sidebar}>
                <Nav>
                  <li onClick={this.getPage} className={(!this.state.activeMenu)?"active":""}>
                    <NavLink
                      to="/cms"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <p>Proses Produksi</p>
                    </NavLink>
                  </li>
                  <li onClick={this.getPage} className={(this.state.activeMenu === 'sertifikat')?"active":""}>
                    <NavLink
                      to="/cms/sertifikat"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <p>Sertifikat</p>
                    </NavLink>
                  </li>
                  <li onClick={this.getPage} className={(this.state.activeMenu === 'tim')?"active":""}>
                    <NavLink
                      to="/cms/tim"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <p>Tim Manajemen</p>
                    </NavLink>
                  </li>
                  <li onClick={this.getPage} className={(this.state.activeMenu === 'faq')?"active":""}>
                    <NavLink
                      to="/cms/faq"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <p>FAQ</p>
                    </NavLink>
                  </li>
                </Nav>
              </div>
            </div>
            <div className={(this.state.sidebarToggle)?'cms-container':'cms-container action'}>
                <div className="cms-navbar">
                    <div className="cms-navbar-toggler" style={{flex:'1'}}>
                      <button onClick={this.handleToggle}>
                        <i className='fas fa-bars'></i>
                      </button>
                    </div>
                    {/* <div className="cms-navbar-title">
                      Pengaturan Proses
                    </div> */}
                    <div>
                      <button style={{
                        margin:'10px',
                        backgroundColor:'transparent',
                        color:'#888',
                        border:'none'
                      }}
                        onClick={this.handleLogout}
                      >
                        <i class='fas fa-sign-out-alt'></i> Logout
                      </button>
                    </div>
                </div>
                <div className='cms-content'>
                  <Route/>
                </div>
                <Footer/>
            </div>
        </div>
        {(this.props.loading.loading)?
          <div 
            style={{
              position:'fixed',
              width:'100%',
              height:'100vh',
              backgroundColor:'rgba(0,0,0,0.4)',
              zIndex:1060,
              textAlign:'center',
              paddingTop:'40vh'
            }}
          >
            <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        :null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {language,loading} = state;
  return {
    language,
    loading
  };
};

// export default Index;
export default withRouter(connect(mapStateToProps)(Index));
