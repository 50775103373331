import {combineReducers} from 'redux';
import {language} from './language';
import {loading} from './loading';

const rootReducer = combineReducers({
    language,
    loading
});

export default rootReducer;
