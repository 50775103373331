import * as types from '../constants/action-types';
const initialState = {
    language : 'indo'
}

export function language(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_LANGUAGE:
            return {
                ...state, language: action.data
            };
        default:
            return state
    }
}