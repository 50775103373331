// import { api } from '../api/';
import * as types from '../constants/action-types';
// import { history } from '../helpers';

const changeLanguage = (lang) => {
  return (dispatch) => {
    try {
      dispatch(language(types.CHANGE_LANGUAGE, lang));
    } catch (error) {
      return error;
    }   
  }
}

export const language = (type, data) => {
  return {
    type: type,
    data: data,
  }
}

export const LanguageActions = {
    changeLanguage,
}
