import React, {Component} from 'react';
import {withRouter} from 'react-router';
import { Row,Col } from "reactstrap";
import {connect} from 'react-redux';
    
class App extends Component {

    render() {
        return (
            <div>
                {/* <IndexHeader /> */}
                <div className="main">
                    <section className='section-lg section section-search'>
                        <div className='container'>
                            <Row>
                                <Col md='6' style={{textAlign:'center'}}>
                                    <img src={require('../../assets/img/sierad/ayam (1).png').default} alt="chicken"/>
                                </Col>
                                {/* <Col md='1' style={{textAlign:'center'}}>
                                </Col> */}
                                <Col md='6'>
                                    <div className="section-search-content">
                                        <h2 style={{marginBottom:'0px', fontWeight:'800'}}>Sistem Jaminan Halal</h2>
                                        <h4 style={{margin:'0 0 1.5rem 0', fontWeight:'400'}}>Sreeya Sewu Indonesia</h4>
                                        <p style={{marginBottom:'1rem'}}>Sebagai perusahaan yang mengedepankan produk halal dan bermutu, Sreeya berkomitmen untuk selalu memenuhi kriteria halal sesuai dengan Sistem Jaminan Halal yang berlaku.</p>
                                        <div className="public-telusuri">
                                            <a href="#sistem-jaminan-halal"><button>Telusuri</button></a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <div style={{position:'absolute',width:'100%',marginTop:'-1px'}}>
                        <div className='container' style={{position:'relative', zIndex:'5', lineHeight:'0px'}}>
                            <svg viewBox="0 0 800 30">
                                <path fill="#e5e5e5" d="M 0 0 Q 250 0 300 0 C 350 0 350 30 400 30 C 450 30 450 0 500 0 C 550 0 550 0 800 0 "></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// export default withRouter((App));
const mapStateToProps = (state) => {
    const {language} = state;
    return {
        language
    };
  };
export default withRouter(connect(mapStateToProps)(App));
