import { api } from '../../api';
// import * as types from '../constants/action-types';
// import { history } from '../helpers';

const getListProses = (id) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'process?id=' + id;
            const response = await api.get_public(apiEndpoint);
            return response;
        } catch (error) {
            return error;
        }
    };
};

const getListJustProses = () => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'process-default/';
            const response = await api.get_public(apiEndpoint);
            return response;
        } catch (error) {
            return error;
        }
    };
};

const getProses = (id) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'process/' + id + '/';
            const response = await api.get(apiEndpoint);
            return response;
        } catch (error) {
            return error;
        }
    };
};

const updateProses = (id, payload) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'process/' + id + '/';
            const response = await api.put(apiEndpoint, payload);
            return response;
        } catch (error) {
            return error;
        }
    };
};

const addImageProses = (id, payload) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'process/' + id + '/image/';
            const response = await api.post_auth(apiEndpoint, payload);
            return response;
        } catch (error) {
            return error;
        }
    };
};

const deleteImageProses = (id) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'image/' + id + '/';
            const response = await api.deleted(apiEndpoint);
            return response;
        } catch (error) {
            return error;
        }
    };
};


export const auths = (type, data) => {
    return {
        type: type,
        data: data,
    }
}

export const ProsesActions = {
    getListProses,
    getProses,
    updateProses,
    addImageProses,
    deleteImageProses,
    getListJustProses
}
