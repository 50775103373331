import axios from 'axios';
import config from './config';

export const api = {
  get,
  post,
  post_auth,
  put,
  download,
  deleted,
  post_login,
  get_public
};

async function get_public(apiEndpoint){
  try {
    const response = await axios.get(config.baseUrl+apiEndpoint);
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }  
}

async function get(apiEndpoint){
  try {
    const response = await axios.get(config.baseUrl+apiEndpoint, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access')
      }
    });
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }  
}

function put(apiEndpoint, payload) {
  return axios.put(config.baseUrl+apiEndpoint, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access'),
    }
  })
  .then((response) => {
    return response;
  }).catch((error) => {
    return Promise.reject(error.response);
  });
}

async function post(apiEndpoint, payload, ) {
  try {
    const response = await axios.post(config.baseUrl+apiEndpoint, payload)
    return response;
  } catch (error) {
    console.log(error)
    return Promise.reject(error.response); 
  }
}

async function post_login(apiEndpoint, payload, page) {
  try {
    const response = await axios.post(config.baseUrl+apiEndpoint, payload, {
      headers: {
        'Content-Type': 'application/json',
        'PageCategory': page,
      }
    })
    return response;
  } catch (error) {
    console.log(error)
    return Promise.reject(error.response); 
  }
}

async function post_auth(apiEndpoint, payload) {
  try {
    const response = await axios.post(config.baseUrl+apiEndpoint, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access'),
      }
    })
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

function download(apiEndpoint, payload) {
  return axios.post(config.baseUrl+apiEndpoint, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access'),
    },
    responseType: 'blob',
  })
  .then((response) => {
    return response;
  }).catch((error) => {
    return Promise.reject(error.response);
  });
}

function deleted(apiEndpoint) {
  return axios.delete(config.baseUrl+apiEndpoint, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access'),
    }
  })
  .then((response) => {
    return response;
  }).catch((error) => {
    return Promise.reject(error.response);
  });
}
