import React, {Component} from 'react';
import {withRouter} from 'react-router';
// import DemoFooter from "../components/Footers/DemoFooter.js";
import FooterDark from "../components/Footers/FooterDark";
import { Row,Col,Input,Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import {connect} from 'react-redux';

import {ContactUsActions} from "../../../redux/actions";
    
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            email:'',
            phone:'',
            enquiry:'',
            modal:false,
            error:false,
            loading:false,
        }
    }

    toggle = () => {
        this.setState({
            modal:!this.state.modal
        })
    };

    handleChange=(head,event)=>{
        this.setState({
            [head]:event.target.value
        })
    }
    handleSubmit=async(event)=>{
        event.preventDefault();
        this.setState({
            loading:true
        })
        const {name,email,phone,enquiry} = this.state
        let payload = {
            name:name,
            email:email,
            phone:phone,
            enquiry:enquiry
        }
        const {dispatch} = this.props;
        let response = await dispatch(ContactUsActions.sendMessage(payload));
        if(response){
            if(!response.data.error){
                this.setState({
                    error:false,
                    loading:false
                })
                this.toggle()
            } else {
                this.setState({
                    error:false,
                    loading:false
                })
                this.toggle()
            }
        } else {
            this.setState({
                error:false,
                loading:false
            })
            this.toggle()
        }
    }

    componentWillUpdate(prevProps) {
        if(prevProps.location.state){
            if (this.props.location.pathname !== prevProps.location.state.prevPath) {
            window.scrollTo(0, 0)
            }
        }
    }

    render() {
        return (
            <div>
                <div className="main">
                    <section className='section-lg section' style={{backgroundColor: '#f4f4f4', textAlign:'center', padding:'160px 0 100px 0'}}>
                        <div className="container">
                            <div className="public-contact-box">
                                <Row>
                                    <Col md="3">
                                        <h3 style={{marginTop:'0'}}>Hubungi Kami</h3>
                                    </Col>
                                    <Col md="9">
                                    <form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <p>Nama</p>
                                                <Input onChange={(e)=>this.handleChange('name',e)} value={this.state.name} type="text" required placeholder="Masukkan Nama Anda *"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <br/>
                                                <p>Email</p>
                                                <Input onChange={(e)=>this.handleChange('email',e)} value={this.state.email} type="email" required placeholder="Masukkan Email Anda *"/>
                                            </Col>
                                            <Col md="6">
                                                <br/>
                                                <p>Telepon</p>
                                                <Input onChange={(e)=>this.handleChange('phone',e)} value={this.state.phone} type="number" required placeholder="Masukkan Telepon Anda *"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <br/>
                                                <p>Pesan</p>
                                                <Input onChange={(e)=>this.handleChange('enquiry',e)} value={this.state.enquiry} type="textarea" required placeholder="Masukkan Pesan Anda disini *"/>
                                            </Col>
                                            <Col md="12" style={{display:'flex',flexDirection: 'row-reverse'}}>
                                                <button type='submit'><i className='fas fa-paper-plane'></i></button>
                                                {(this.state.loading)?
                                                <div class="lds-facebook"><div></div><div></div><div></div></div>
                                                :''}
                                            </Col>
                                        </Row>
                                        </form>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </section>
                    <FooterDark />
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody>
                        {(this.state.error)?
                        <div style={{textAlign:'center'}}>
                            <img style={{width:'100px'}} src={require('../../cms/assets/img/warning.png').default} alt="warning"/>
                            <p style={{fontWeight:'400'}}>Terjadi galat dalam pengiriman pesan anda.</p>
                        </div>
                        :
                        <div style={{textAlign:'center'}}>
                            <img style={{width:'100px'}} src={require('../../cms/assets/img/success-sierad.png').default} alt="success"/>
                            <p style={{fontWeight:'400'}}>Pesan kamu berhasil dikirim.</p>
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter style={{padding:'1rem'}}>
                    <Button onClick={this.toggle}>OKE</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

// export default withRouter((App));
const mapStateToProps = (state) => {
    const {language} = state;
    return {
        language
    };
  };
export default withRouter(connect(mapStateToProps)(App));
