import React, {Component} from 'react';
import Routes from './Route/route-main';
// import Drawers from './components/dashboard/templates/Drawers';
// import {connect} from 'react-redux';
import {withRouter} from 'react-router';
// import jwt_decode from 'jwt-decode';
// import {dataActions, profileActions} from "./actions";
// import Dashboard from './Dashboard';
// import Login from './Login';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-171129510-3');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {


    render() {
        return (
            <div>
                <Routes/>
            </div>
        )
    }
}

// const mapStateToProps = (state) => {
//     const {loggingIn, messages} = state.auths;
//     return {
//         loggingIn,
//         messages
//     };
// };

// export default withRouter(connect(mapStateToProps)(App));
export default withRouter((App));
