import { api } from '../../api';
// import * as types from '../constants/action-types';
// import { history } from '../helpers';

const getListManagement = () => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'management-teams/';
            const response = await api.get_public(apiEndpoint);
            return response;
        } catch (error) {
              return error;
        }
    };
};

const getManagement = (id) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'management-team/'+id+'/';
            const response = await api.get(apiEndpoint);
            return response;
        } catch (error) {
              return error;
        }
    };
};

const updateManagement = (id,payload) => {
  return async (dispatch) => {
      try {
          const apiEndpoint = 'management-team/'+id+'/image/';
          const response = await api.put(apiEndpoint,payload);
          return response;
      } catch (error) {
            return error;
      }
  };
};


export const auths = (type, data) => {
  return {
    type: type,
    data: data,
  }
}

export const ManagementActions = {
    getListManagement,
    updateManagement,
    getManagement
}
