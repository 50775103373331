import React from "react";
import {connect} from 'react-redux';

import { EditorState, convertToRaw,ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FormGroup,Label,Input,Button,Modal, ModalBody, ModalFooter} from 'reactstrap';
import {Link} from "react-router-dom";
// reactstrap components

import {FaqActions,LoadingActions} from "../../../redux/actions";

class Index extends React.Component {
    state = {
        editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML('<p>My initial content.sdfsdfdfsdfsddfs</p><img src="https://lh6.googleusercontent.com/SCWh_78r-vZQlpIPfKrZLYKWdsBQYa1xTJ94gUK98krAIWiKi-ZP3CsEMEDOzXM8qKb1w6GU_RhBlbwXWgMJ=w1920-h943-rw" alt="undefined" style="height: auto;width: auto"/><p></p>')
            )
        ),
        id:'',
        question:'',
        number:'',
        modal:false,
    }
    toggle = () => {
        this.setState({
            modal:!this.state.modal
        })
    };
    onChangeQuestion = (event) => {
        this.setState({
            question:event.target.value
        })
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    getPage= async()=>{
        const {dispatch} = this.props;
        await dispatch(LoadingActions.changeLoading(true));
        let endpoint = window.location.pathname.split('/');
        let response = await dispatch(FaqActions.getFaq(endpoint[4]));
        if(response){
            if(!response.data.error){
                this.setState({
                    id:endpoint[4],
                    number:response.data.faqs.number,
                    question:response.data.faqs.title,
                    editorState:EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(response.data.faqs.content||"")
                        )
                    )
                })
                await dispatch(LoadingActions.changeLoading(false));
            } else {
                this.setState({
                    id:endpoint[4],
                    number:'',
                    question:'',
                    editorState:EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML("")
                        )
                    )
                })
                await dispatch(LoadingActions.changeLoading(false));
            }
        } else {
            this.setState({
                id:endpoint[4],
                number:'',
                question:'',
                editorState:EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML("")
                    )
                )
            })
            await dispatch(LoadingActions.changeLoading(false));
        }
    }
    componentWillMount(){
        this.getPage();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };
    onSubmit = async()=>{
        const {dispatch} = this.props;
        await dispatch(LoadingActions.changeLoading(true));
        let payload = {
            number:this.state.number,
            title:this.state.question,
            content:draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        }
        let response = await dispatch(FaqActions.FaqEdit(this.state.id,payload));
        if(response){
            if(!response.data.error){
                this.props.history.push('/cms/faq');
                await dispatch(LoadingActions.changeLoading(false));
            } else {
                await dispatch(LoadingActions.changeLoading(false));
            }
        } else {
            await dispatch(LoadingActions.changeLoading(false));
        }
    }

    onDelete = async()=>{
        const {dispatch} = this.props;
        await dispatch(LoadingActions.changeLoading(true));
        let response = await dispatch(FaqActions.FaqDelete(this.state.id));
        if(response){
            if(!response.data.error){
                this.props.history.push('/cms/faq');
                await dispatch(LoadingActions.changeLoading(false));
            } else {
                await dispatch(LoadingActions.changeLoading(false));
            }
        } else {
            await dispatch(LoadingActions.changeLoading(false));
        }
    }
    render(){
        const { editorState } = this.state;
        return (
            <div>
                <div className="cms-container-content">
                    <div style={{display:'flex',marginBottom:'2rem'}}>
                        <h4 style={{flex:'1'}}>Ubah FAQ</h4>
                        <Button color="danger" style={{fontSize:'14px'}} onClick={this.toggle}><i class='fas fa-trash-alt'></i> Hapus</Button>
                    </div>
                    <div>
                        <FormGroup>
                            <Label for="exampleEmail">Pertanyaan</Label>
                            <Input
                                type="text"
                                name="pertanyaan"
                                id="exampleEmail"
                                placeholder="Tuliskan Pertanyaan"
                                value={this.state.question}
                                onChange={this.onChangeQuestion}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Jawaban</Label>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange}
                            />
                            {/* <textarea
                                disabled
                                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                            /> */}
                        </FormGroup>
                        <div style={{textAlign:'center'}}>
                            <br/>
                            <Link to="/cms/faq"><Button style={{margin:'8px'}}><i className='fas fa-reply'></i> Kembali</Button></Link>
                            <Button color="primary" style={{margin:'8px'}} onClick={this.onSubmit}><i className='fas fa-edit'/> Ubah</Button>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody style={{padding:'1rem'}}>
                        <div style={{textAlign:'center'}}>
                            <img style={{width:'100px'}} src={require('../assets/img/warning.png').default} alt="warning"/>
                            <p style={{fontWeight:'400'}}>Apakah kamu yakin akan menghapus pertanyaan ini?</p>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{padding:'1rem'}}>
                    <Button color="danger" onClick={this.onDelete}><i class='fas fa-trash-alt'></i> Hapus</Button>{' '}
                    <Button onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  const {language} = state;
  return {
    language
  };
};

// export default Index;
export default connect(mapStateToProps)(Index);
