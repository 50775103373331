import React from "react";
import {connect} from 'react-redux';
// reactstrap components
import { Button,Table, Modal, ModalBody, ModalFooter } from 'reactstrap';

import {SertifikatActions,LoadingActions} from "../../../redux/actions";

import Dropzone from 'react-dropzone'

class Index extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			list: [],
			selectedId:'',
			modal:false,
			image:null,
        }
    }
	componentWillMount(){
        this.getSertifikat();
    }
	getSertifikat= async ()=>{
		const {dispatch} = this.props;
		await dispatch(LoadingActions.changeLoading(true));
		let response = await dispatch(SertifikatActions.getListSertifikat());
		if(response){
			if(!response.data.error){
				let data = response.data;
				this.setState({
					list:data
				})
				await dispatch(LoadingActions.changeLoading(false));
			} else {
				this.setState({
					list:[]
				})
				await dispatch(LoadingActions.changeLoading(false));
			}
		} else {
			this.setState({
				list:[]
			})
			await dispatch(LoadingActions.changeLoading(false));
		}
	}
	toggle = (id) => {
		this.setState({
			modal:!this.state.modal,
			selectedId:id,
			image:null,
		})
	}

	onDrop = (acceptedFiles) => {
		this.setState({
			image:acceptedFiles[0]
		})
	}

	handleSubmit = async() => {
		const {dispatch} = this.props;
		await dispatch(LoadingActions.changeLoading(true));
		const {selectedId,image} = this.state;
		let altText = 'Sertifikat MUI'
		if(parseInt(selectedId) === 1){
			altText = "Sertifikat Sistem Jaminan Halal"
		}
		const fd = new FormData();
		fd.append('uploadedImage',image);
		fd.append('altText',altText)
		this.toggle();
		let response = await dispatch(SertifikatActions.updateSertifikat(selectedId,fd));
		if(response){
			if(!response.data.error){
				this.getSertifikat();
				await dispatch(LoadingActions.changeLoading(false));
			} else {
				this.getSertifikat();
				await dispatch(LoadingActions.changeLoading(false));
			}
		} else {
			this.getSertifikat();
			await dispatch(LoadingActions.changeLoading(false));
		}
	}
	
	render(){
		return (
			<>
				<div className='cms-container-content'>
					<h4 style={{marginBottom:'2rem'}}>Pengaturan Sertifikat</h4>
					<div style={{width:'100%',overflow:'auto'}}>
						<Table>
							<thead>
								<tr>
								<th>#</th>
								<th>Nama Sertifikat</th>
								<th>Gambar</th>
								<th>Aksi</th>
								</tr>
							</thead>
							<tbody>
								{this.state.list.map((list,idx)=>{
									return (
										<tr key={idx}>
											<th scope="row">{idx+1}</th>
											<td>{list.altText}</td>
											<td>
												{(list.src)?
													(
														// <img style={{width:'250px'}} src={'https://lh3.googleusercontent.com/u/0/d/'+list.src+'=w1366-h657-iv1'} alt={list.altText}/>
														<img style={{width:'250px'}} src={list.src} alt={list.altText}/>
													)
													:
													('null')
												}	
											</td>
											<td><Button style={{fontSize:'14px'}} onClick={()=>this.toggle(list.id)||''}>Ubah Gambar</Button></td>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</div>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
						<ModalBody style={{padding:'1rem'}}>
							<h4 style={{paddingTop:'0'}}>Ubah Gambar </h4>
							<br/>
							<div className="uploadzone">
								<Dropzone onDrop={this.onDrop}>
									{({getRootProps, getInputProps}) => (
										<div {...getRootProps()}>
										<input {...getInputProps()} />
											
											{(this.state.image)?
												(this.state.image.path)
												:
												('Click or Drag Image here to upload a file!')
											}
										</div>
									)}
								</Dropzone>
							</div>
						</ModalBody>
						<ModalFooter style={{padding:'1rem'}}>
						<Button color="primary" onClick={this.handleSubmit}>Ubah</Button>{' '}
						<Button color="secondary" onClick={this.toggle}>Batal</Button>
						</ModalFooter>
					</Modal>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const {language,loading} = state;
	return {
	  language,
	  loading
	};
};

// export default Index;
export default connect(mapStateToProps)(Index);
