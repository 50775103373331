import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay
} from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
// import DatePicker from 'react-date-picker';
import DatePicker from 'react-datepicker';
import { Modal, ModalBody, } from "reactstrap";
import { Link } from 'react-router-dom';
// import { ScrollTo, ScrollArea } from "react-scroll-to";

import { ProsesActions } from "../../../redux/actions";

import HalalBlockchainGreen from '../../../assets/img/hbc-full-green.png'
import HalalBlockchainWhite from '../../../assets/img/hbc-green-white.png'
import SreeyaWhite from '../../../assets/img/sreeya_white.png'
// import antiManipulasiIcon from '../../../assets/img/anti-manipulasi.svg'
// import halalThayibIcon from '../../../assets/img/halal-thayib.svg'
// import ayamBerkualitasIcon from '../../../assets/img/ayam-berkualitas.svg'
import gif1 from '../../../assets/img/01-compress.gif'
import gif2 from '../../../assets/img/02-compress.gif'
import gif3 from '../../../assets/img/04-compress.gif'
import calendarIcon from '../../../assets/img/calendar.svg'
// import hbcIcon from '../../../assets/img/hbc-menu.svg'
import hbcIconPNG from '../../../assets/img/hbc-menu.png'
import faqIcon from '../../../assets/img/faq.svg'
import geekModeIcon from '../../../assets/img/geek-mode.svg'
import arrowLeftIcon from '../../../assets/img/arrow-left.png'
import warnWhiteIcon from '../../../assets/img/warn-white.svg'
import sryKV from '../../../assets/img/SRY KV Halal Blockchain.jpg'
import ayamHidup from '../../../assets/img/Ayam Hidup.png'
import segel from '../../../assets/img/Truk Segel.png'
import '../../../assets/css/halal-blockchain.scss'


// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, A11y]);

export const dateFormatting = (date) => {
    const today = new Date(date)
    const year = today.getFullYear()
    const month = today.getMonth()
    const day = today.getDate()
    let monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    const finalDate = day + " " + monthName[month] + " " + year
    return finalDate
}

export const dayBefore = (date) => {
    const tanggal = new Date(date)
    tanggal.setDate(tanggal.getDate()-1)
    return tanggal
}

export const dateIDFormatting = (date) => {
    const today = new Date(date)
    const year = today.getFullYear()
    const month = ("0" + (today.getMonth() + 1)).slice(-2)
    const day = ("0" + today.getDate()).slice(-2)
    const finalDate = year + "" + month + "" + day
    return finalDate
}


export function addDays(date, days) {
    let theDate = new Date(date);
    theDate.setDate(theDate.getDate() + days);
    return theDate;
}

export function subtractDays(date, days) {
    let theDate = new Date(date);
    theDate.setDate(theDate.getDate() - days);
    return theDate;
}

export const ProgressBar = ({ width, percent }) => {
    // progress bar at loading page
    const [value, setValue] = React.useState(0);
    React.useEffect(() => {
        setValue(percent * width);
    });

    return (
        <div>
            <div className="progress-div" style={{ width: width }}>
                <div style={{ width: `${value}px` }} className="progress" />
            </div>
        </div>
    );
};


const CustomInputDatePicker = React.forwardRef(
    ({ value, onClick }, ref) => (
        <img alt="icon" src={calendarIcon} onClick={onClick} ref={ref} />
    ),
);


class HalalBlockchain extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            process: [],
            search: '',
            loading: false,
            percentLoading: 0.1, //range: 0 to 1
            carouselScreen: false,
            modalPenyembelih: { show: false, nama: "", noSertifkat: "", isActive: undefined, urlSertifikat: "" },
            selectedDate: undefined,
            scroll: true,
            interval: null,
            reachEnd:false,
        };
    }


    componentWillMount() {
        // this.getParameter();
    }
    componentDidMount() {
        this.setState({
            percentLoading: 0.6
        })
        setTimeout(() => {
            this.getParameter();
        }, 2000);
    }


    componentDidUpdate() {
        if (this.state.percentLoading >= 1 && !this.state.carouselScreen) {
            //direct to carousel screen
            setTimeout(() => {
                this.setState({
                    carouselScreen: true
                })
            }, 4000);
        }
    }

    getParameter = () => {
        //handle get id from url params
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id')
        if (id) {
            this.setState({
                search: id
            })
            this.getListProses(id);
        } else {
            this.getListProses('latest');
        }
    }

    getListProses = async (id) => {
        this.setState({
            loading: true,
            carouselScreen: false,
            // percentLoading: 0.4
        })
        const { dispatch } = this.props;
        let response = await dispatch(ProsesActions.getListProses(id));
        this.setState({
            percentLoading: 0.8
        })
        if (response) {
            if (!response.data.error) {
                let data = response.data.dataLocal;
                data.sort((a, b) => (a.id > b.id) ? 1 : -1)
                for (let i = 0; i < data.length; i++) {
                    let temp = [];
                    for (let j = 0; j < data[i].processImages.length; j++) {
                        temp.push({
                            src: data[i].processImages[j].src,
                            altText: data[i].processImages[j].altText,
                            caption: ''
                        })
                    }
                    data[i].processImages = temp;
                }
                this.setState({ //action success
                    process: data,
                    loading: false,
                    percentLoading: 1
                })
            } else {
                this.setState({ //action error
                    process: [],
                    loading: false,
                    percentLoading: 0
                })
            }
        } else {
            this.setState({ //action error
                process: [],
                loading: false,
                percentLoading: 0
            })
        }
    }

    handleChangeDate = (date) => {
        if (date) {
            this.setState({ selectedDate: new Date(date) })
            let id = dateIDFormatting(date)
            this.getListProses(id);
        }
    }

    isWeekday = date => {
        const day = new Date(date).getDay();
        return day !== 0
        // && day !== 6;
    };

    getScroll = () => {
        let inter = this.state.interval;
        inter = clearInterval(inter)
        inter = setInterval(this.updateScroll, 5000)
        this.setState({
            scroll: false,
            interval: inter
        })
    }

    updateScroll = () => {
        this.setState({
            scroll: true,
        })
    }

    reachEnd = (e) =>{
        if(e.activeIndex === 2){
            this.setState({
                reachEnd: true,
            })
        } else {
            this.setState({
                reachEnd: false,
            })
        }
    }


    render() {
        const { process } = this.state;
        let a = false;
        if (!this.state.carouselScreen) {
            return ( //Loading Page
                <div className="hbc-container">
                    <div className="hbc-loading">
                        <img src={HalalBlockchainWhite} alt="logo-hbc" className="mx-auto  d-flex"
                        />
                        <section className=" main-info">
                            {
                                this.state.percentLoading > 0 ?
                                    <>
                                        <div style={{textAlign:'center'}}>
                                            <img style={{width:'85%'}} src={sryKV} alt="Sreeya KV" />
                                        </div>
                                        {/* <div className="d-flex mb-4 align-items-center">
                                            <img src={antiManipulasiIcon} alt="anti manipulasi" />
                                            <h3 className="text-uppercase ml-4 my-0">Blockchain <br /> Anti Manipulasi <br />Data</h3>
                                        </div>
                                        <div className="d-flex mb-4 align-items-center">
                                            <img src={halalThayibIcon} alt="anti manipulasi" />
                                            <h3 className="text-uppercase ml-4 my-0">Halalan <br /> Thayyiban</h3>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <img src={ayamBerkualitasIcon} alt="anti manipulasi" />
                                            <h3 className="text-uppercase ml-4 my-0">Ayam <br /> Berkualitas</h3>
                                        </div> */}
                                    </> :
                                    <>
                                        <img alt="icon" src={warnWhiteIcon} className="d-flex m-auto" />
                                        <h3 className="text-uppercase text-center mt-4">Data pada tanggal  <br />  yang Anda pilih <br /> tidak tersedia</h3>
                                    </>
                            }

                        </section>

                        {this.state.percentLoading > 0 ?
                            <section className="loading mx-auto">
                                <h5>Loading . . .</h5>
                                <ProgressBar width={300} percent={this.state.percentLoading} />
                            </section>
                            : <section className="loading mt-5">
                                <button style={{ backgroundColor: "#B4D449", width: "75%", height: "40px", color: "#fff", fontWeight: "700", border: "1px solid #fff", borderRadius: "2rem" }} onClick={() => this.getListProses('latest')}>Kembali</button>
                            </section>
                        }
                        <section className="mt-auto">
                            <div className="gradient-line loading-line"></div>
                            <div className="footer-loading">
                                <img src={SreeyaWhite} alt="logo sreeya" width="150px" height="auto" className="mt-auto mx-auto d-flex py-4" />
                            </div>
                        </section>
                    </div>
                </div>
            )
        }

        return (//Caraousel Page
            <div className="hbc-container">
                <div className="hbc-carousel">
                    <Swiper
                        tag="section"
                        wrapperTag="ul"
                        spaceBetween={0}
                        slidesPerView={'auto'}
                        centeredSlides={true}
                        navigation={{
                            hiddenClass: "swiper-button-hidden",
                        }}
                        pagination={{
                            clickable: true,
                            type: "custom",
                            renderCustom: function (swiper, current, total) {
                                swiper.autoplay.stop = () => {
                                    return true
                                }
                                return (
                                    `<div class="pagination-bar-container">
                                        <span class=${current >= 1 ? (current === 1) ? 'pagination-bar-progress' : 'pagination-bar-active' : ''} style= ${current === 1 ? "animation: progressBar 12s ease-in-out" : ""}> </span>
                                    </div>
                                    <div class="pagination-bar-container">
                                        <span class=${current > 1 ? (current === 2) ? 'pagination-bar-progress' : 'pagination-bar-active' : ''} style= ${current === 2 ? "animation: progressBar 12s ease-in-out" : ""}> </span>
                                    </div>
                                    <div class="pagination-bar-container">
                                        <span class=${current > 2 ? (!swiper.autoplay.stop()) ? 'pagination-bar-progress' : 'pagination-bar-active' : ''} style="
                                        animation: progressBar 20s ease-in-out;
                                    "> </span>
                                    </div>`
                                )
                            }
                        }}
                        loop={false}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                            stopOnLastSlide: true
                        }}
                        onSlideChange={(e)=>this.reachEnd(e)}
                    >
                        {/**** SLIDE PERTAMA ****/}
                        <SwiperSlide tag="li" data-swiper-autoplay="17000">
                            <div className="slide-content">
                                <div className="d-flex justify-content-center flex-column align-items-center image-container" >
                                    <a href="https://www.sreeyasewu.com/en/technology.halal-blockchain" target="_blank" rel="noreferrer">
                                        <img src={HalalBlockchainGreen} alt="logo-hbc" />
                                    </a>
                                    <img src={gif1} alt="gif" />
                                </div>
                                <div className="slide-content_info">
                                    <header>
                                        <h3 className="font19 weight-700 hbc-text-shadow d-flex justify-content-center mt-2 mb-3">TERJAMIN SUMBER NYA</h3>
                                    </header>
                                    <section>
                                        <div className="mb-2">
                                            <div className="key-and-value"> <span className="key">Kode Panen</span><span className="value">{process.length > 0 ? dateIDFormatting(process[0].date) : ""}</span>
                                            </div>
                                            <div className="key-and-value"> <span className="key">Asal Farm </span> <span className="value">PT Sreeya</span>
                                            </div>
                                            <div className="key-and-value"> <span className="key">Daerah</span> <span className="value">Jawa Barat/Banten</span>
                                            </div>
                                            <div className="key-and-value last"> <span className="key">Tanggal Panen</span> <span className="value">{(process.length > 0) ? dateFormatting(dayBefore(process[0].date)) : ''} </span>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <p className="font15"> <span className="weight-700"> PT Sreeya Sewu Indonesia, Tbk </span> Lebih 36 tahun menghasilkan ayam :<br /> </p>
                                            <ul className="font15">
                                                <li className="mb-1 pl-1">Pakan & Vitamin terbaik</li>
                                                <li className="mb-1 pl-1">Animal Welfare : hidup nyaman</li>
                                                <li className="pl-1">Bersih, dijaga Bio-Security ketat</li>
                                            </ul>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/**** SLIDE KEDUA ****/}
                        <SwiperSlide tag="li" data-swiper-autoplay="17000">
                            <div className="slide-content">
                                <div className="d-flex justify-content- center flex-column align-items-center image-container" >
                                    <a href="https://www.sreeyasewu.com/en/technology.halal-blockchain" target="_blank" rel="noreferrer">
                                        <img src={HalalBlockchainGreen} alt="logo-hbc" />
                                    </a>
                                    <img src={gif2} alt="gif" />
                                </div>
                                <div className="slide-content_info">
                                    <h3 className="font19 weight-700 hbc-text-shadow d-flex justify-content-center mt-2 mb-3">DIPERIKSA KETAT</h3>
                                    <section>
                                        <div className="mb-2">
                                            <div className="key-and-value"> <span className="key">Rumah Potong</span><span className="value">Sreeya-Bogor</span>
                                            </div>
                                            <div className="key-and-value"> <span className="key">Produksi</span><span className="value">{(process.length > 0) ? dateFormatting(process[1].date) : ''}</span>
                                            </div>
                                            <div className="key-and-value"> <span className="key">Ayam Sehat</span><span className="value">LULUS</span>
                                            </div>
                                            <div className="key-and-value"> <span className="key">Kualitas</span><span className="value">GRADE A</span>
                                            </div>
                                            <div className="key-and-value last"> <span className="key">Waktu Istirahat</span>
                                                <p className="d-flex flex-column align-items-end mb-0"> <span className="value">28-32 Menit</span><span className="font12 sub-value">(Ayam Tidak Stress)</span> </p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="font15"> <span className="weight-700">Sreeya-Bogor</span> Rumah Potong dengan NKV lvl 1 (Terbaik), dari Kementerian Pertanian RI. Terbaik dari ratusan rumah potong di Indonesia merupakan buah komitmen kualitas kami. </p>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/**** SLIDE KETIGA ****/}
                        <SwiperSlide tag="li" data-swiper-autoplay="30000">
                            <div className="slide-content">
                                <div className="d-flex justify-content-center flex-column align-items-center image-container" >
                                    <a href="https://www.sreeyasewu.com/en/technology.halal-blockchain" target="_blank" rel="noreferrer">
                                        <img src={HalalBlockchainGreen} alt="logo-hbc" />
                                    </a>
                                    <img src={gif3} alt="gif" />
                                </div>
                                <div className="slide-content_info">
                                    <h3 className="font19 weight-700 hbc-text-shadow d-flex justify-content-center mt-2 mb-3">SETIAP AYAM TERJAMIN HALAL</h3>
                                    <section onScroll={this.getScroll}>
                                        <div className={(this.state.reachEnd)?!this.state.scroll ? "timeline-scroll" : "timeline-scroll-run":"timeline-scroll"}>
                                            <div className="timeline-v">
                                                <div className="timeline-items-v" >
                                                    <div className="timeline-item-v">
                                                        <div className="timeline-content-v">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Kondisi Ayam Sebelum Sembelih</p>
                                                            <img style={{width:'45%', margin:'4px 0px'}} src={ayamHidup} alt="Ayam Hidup" />
                                                            <h5 className="my-2 dropdown-value">HIDUP</h5>
                                                            <p className="font12 mb-0">(bukan bangkai)</p>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-item-v" >
                                                        <div className="timeline-content-v">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Sertifikasi MUI Juru Sembelih</p>
                                                            <div className="juru-sembelih-image-container mt-2">
                                                                {process.length > 0 && this.state.process[6].data.map((el) => {
                                                                    if (el?.id) {
                                                                        return (
                                                                            <div className="image" onClick={() => this.setState({ modalPenyembelih: { show: true, nama: el?.name, noSertifkat: el?.noSertifikat, isActive: el?.isActive, urlSertifikat: el?.sertifikatImage } })}>
                                                                                <img src={el?.photo} alt="prof-pic" width="100px" height="auto" />
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                    
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="timeline-item-v">
                                                        <div className="timeline-content-v">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Sembelih Baca Asma Allah & Hadap Kiblat</p>
                                                            <h5 className="my-2 dropdown-value mt-2 mb-0">LULUS</h5>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-item-v">
                                                        <div className="timeline-content-v">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Penirisan Darah</p>
                                                            <h5 className="my-2 dropdown-value">LULUS</h5>
                                                            <p className="font12 mb-0">(tiris, {`>`} 3 menit)</p>

                                                        </div>
                                                    </div>
                                                    <div className="timeline-item-v">
                                                        <div className="timeline-content-v">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Kondisi Ayam Setelah Sembelih</p>
                                                            <h5 className="my-2 dropdown-value">MATI OLEH JURU SEMBELIH</h5>
                                                            <p className="font12 mb-0">(bukan oleh mesin)</p>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-item-v">
                                                        <div className="timeline-content-v">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Kualitas Organ Dalam</p>
                                                            <h5 className="my-2 dropdown-value mt-2 mb-0">KUALITAS BAIK</h5>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-item-v" >
                                                        <div className="timeline-content-v">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Sortir Ayam Tidak Halal</p>
                                                            <div className="my-2">
                                                                <h5 className="my-2 dropdown-value mb-0 ">{process.length > 0 && this.state.process[6]?.data[0]?.value} ekor</h5>
                                                                <p className="font12 mb-0 pl-1">telah dimusnahkan</p></div>
                                                            <p className="font12 mb-0">3 saluran : Cerna, Nafas & Darah
                                                        tidak potong sempurna</p>

                                                        </div>
                                                    </div>
                                                    <div className="timeline-item-v last" >
                                                        <div className="timeline-content-v last">
                                                            <p className="font15 hbc-text-shadow2 mb-0">Truk Pengiriman</p>
                                                            <img style={{width:'45%', margin:'4px 0px'}} src={segel} alt="Truk Segel" />
                                                            <h5 className="my-2 dropdown-value">SEGEL LENGKAP</h5>
                                                            <p className="font12 mb-0" >cegah curang pengiriman (non-Halal)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="gradient-line"></div>
                    <div className="menu-on-footer">
                        <div className="menu">
                            <DatePicker
                                className="hbc-calendar"
                                onChange={(value) => this.handleChangeDate(value)}
                                selected={this.state.selectedDate}
                                maxDate={subtractDays(new Date(), 1)}
                                // peekNextMonth
                                // showMonthDropdown
                                // showYearDropdown
                                // todayButton="today"
                                name="processTime"
                                filterDate={this.isWeekday}
                                withPortal
                                customInput={
                                    <CustomInputDatePicker />
                                }
                            />
                            <p>Tanggal</p>
                        </div>
                        <Link className="menu"
                            to={{ pathname: "/sistem-jaminan-halal" }} replace target="_blank" rel="noreferrer"
                        >
                            <img alt="icon" src={hbcIconPNG} style={{ width: '43%', height: 'auto' }} />
                            <p>Ayo Cari Tau!</p>
                        </Link>
                        <Link className="menu" to={{ pathname: "/faq" }} target="_blank" rel="noreferrer">
                            <img alt="icon" src={faqIcon} />
                            <p>FAQ</p>
                        </Link>

                        <a href={`https://blockexplorer.sreeyasewu.com/traces?address=0x504Ad30dCDc6D8464274B63D04e28474fdb3516f&version=${dateIDFormatting(process[0]?.date)}`} className="menu" target="_blank" rel="noreferrer">
                            <img alt="icon" src={geekModeIcon} />
                            <p>Geek Mode</p>
                        </a>
                    </div>

                    {this.state.modalPenyembelih.show && // Modal Sertifikat
                        <Modal isOpen={this.state.modalPenyembelih.show} toggle={() => this.setState({ modalPenyembelih: { show: false } })} >
                            <ModalBody style={{ padding: "0" }} >
                                <div className="modal-info-juru-sembelih">
                                    <div className="juru-sembelih-image">
                                        <img alt="sertifikat" src={this.state.modalPenyembelih?.urlSertifikat} />
                                        <p className="button-close" onClick={() => this.setState({ modalPenyembelih: { show: false } })}> <img alt="back" src={arrowLeftIcon} /></p>
                                    </div>
                                    <div className="py-3 px-3 juru-sembelih-info">
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-0 font14" style={{ color: "#929292" }}>Nama Penyembelih</p>
                                            <p className="mb-0 font14" style={{ color: "#3C3C3C" }} >{this.state.modalPenyembelih?.nama}</p>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-0 font14" style={{ color: "#929292" }}>No. Sertifikat</p>
                                            <p className="mb-0 font14" style={{ textDecoration: "underline", color: "#3C3C3C" }}>{this.state.modalPenyembelih?.noSertifkat}</p>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="mb-0 font14" style={{ color: "#929292" }}>Status</p>
                                            {this.state.modalPenyembelih?.isActive === 1 ? <p className="mb-0 font14" style={{ color: "#009552" }}>Aktif </p> : <p className="mb-0 font14" style={{ color: "#929292" }}>Tidak Aktif</p>}
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const { language } = state;
    return {
        language
    };
};
export default withRouter(connect(mapStateToProps)(HalalBlockchain));