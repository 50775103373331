import React, { Component } from 'react';
// import {connect} from 'react-redux';
// import {Link} from "react-router-dom";
import Logo from './containers/LandingPage/assets/img/logo-sieradproduce.png';
import Logo2 from './assets/img/sreeya_warna.png';
import {
    Button,
    Input,
} from "reactstrap";
import { connect } from 'react-redux';
import { LoginActions } from "./redux/actions";
import jwt_decode from 'jwt-decode';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            message: '',
            loginStatus: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleSubmit = async (event) => {
        const { dispatch } = this.props;
        event.preventDefault();
        const { username, password } = this.state;
        if (username !== '' && password !== '') {
            let response = await dispatch(LoginActions.login(username, password, 'public'));
            if (response) {
                if (response.data.error) {
                    this.setState({
                        loginStatus: false,
                        message: response.data.message
                    })
                } else {
                    window.location.reload();
                }
            } else {
                this.setState({
                    loginStatus: false,
                    message: 'Failed to connect API'
                })
            }
        }
    };

    componentWillMount() {
        if (localStorage.getItem('access') !== null) {
            const decoded = jwt_decode(localStorage.getItem('access'));
            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (decoded.exp < currentTimestamp) {
                localStorage.clear();
                this.props.history.push('/login');
            } else {
                this.props.history.push('/cms');
            }
        }
    }

    render() {
        const { username, password, loginStatus } = this.state;
        return (
            <div>
                <div className="login-box">
                    <img className="login-image" src={Logo2} alt={'main logo'} width="160px" height="auto" />
                    <h4>CMS</h4><br />
                    {/* <span style={{color:'red'}}>{loginStatus}</span> */}
                    <form onSubmit={this.handleSubmit}>
                        {(!loginStatus) ? <span style={{ fontSize: '12px', color: 'red' }}>{this.state.message}</span> : <span></span>}
                        <Input value={username} onChange={this.handleChange('username')} placeholder="Username" type="text" required />
                        <Input value={password} onChange={this.handleChange('password')} placeholder="Password" type="password" required />
                        <Button color="info" type="submit">
                            Login
                    </Button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return null;
};

// export default Index;
export default connect(mapStateToProps)(Index);
