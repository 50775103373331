import React, {Component} from 'react';
import {withRouter} from 'react-router';
import LandingPage from './views/Index.js'

class Index extends Component {

    render() {
        return (
            <div>
                <LandingPage/>
            </div>
        )
    }
}

export default withRouter((Index));