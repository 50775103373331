import { api } from '../../api/';
// import * as types from '../constants/action-types';
// import { history } from '../helpers';

const login = (email, pass, page) => {
  return async (dispatch) => {
      try {
          const apiEndpoint = 'login/';
          const payload = {
            usernameEmail: email,
            password: pass
          };
          const response = await api.post_login(apiEndpoint, payload,page);
          if (!response.data.error) {
              localStorage.setItem('auth', true);
              localStorage.setItem('refresh', response.data.token);
              localStorage.setItem('access', response.data.token);
            //   dispatch(auths(types.LOGIN_SUCCESS, response.data.message));
              return response;
          } else {
            return response;
            // dispatch(auths(types.LOGIN_FAILED, response.data.message));
          }
      } catch (error) {
            return error;
      }
  };
};

const logout = () => {
  return async () => {
    localStorage.clear()
    //   const apiEndpoint = 'logout/';
    //   const payload = {
    //       refresh: localStorage.getItem('refresh'),
    //   };
    //   const response = await api.post(apiEndpoint, payload);
    //   return response.status;
  }
};



export const auths = (type, data) => {
  return {
    type: type,
    data: data,
  }
}

export const LoginActions = {
  login,
  logout
}
