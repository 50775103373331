/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";
import { Link } from 'react-router-dom';

function DemoFooter() {
    return (
        <div style={{ position: 'absolute', width: '100%', marginTop: '-50px' }}>
            <div style={{ position: 'relative', width: '100%', backgroundColor: 'transparent', marginBottom: '-1px' }}>
                <div className='container' style={{ position: 'relative', zIndex: '5', lineHeight: '0px' }}>
                    <svg viewBox="0 0 800 30">
                        <path fill="#4f4f4f" d="M 0 30 Q 250 30 300 30 C 350 30 350 0 400 0 C 450 0 450 30 500 30 C 550 30 550 30 800 30 "></path>
                    </svg>
                </div>
            </div>
            <footer className="footer public-footer" style={{ position: 'relative', zIndex: '5' }}>
                <Container>
                    <br />
                    <Row>
                        <Col md="6">
                            <Row>
                                <Col sm="6">
                                    <div style={{ marginBottom: '1rem' }}>
                                        {/* <img src={require('../../assets/img/sierad/logo-white.png').default} alt="logo sierad"/> */}
                                        <img style={{ width: '200px' }} src={require('../../../../assets/img/sreeya_grayscale.png').default} alt="logo sreeya" />
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <table style={{ fontSize: '12px', color: '#888' }}>
                                        <tbody>
                                            <tr style={{ lineHeight: '22px' }}>
                                                <td valign="top" ><i className="fas fa-map-marker-alt" /></td>
                                                <td>
                                                    <b style={{ fontWeight: 'bold' }}>Jakarta</b><br />
                                            Sequis Tower, Lantai 40 <br />
                                            Jl. Jendral Sudirman Kav 71 <br />
                                            Jakarta Selatan 12190
                                        </td>
                                            </tr>
                                            <tr>
                                                <td valign="top"><i className="fas fa-phone" /></td>
                                                <td>
                                                    +62 21 5099 1599
                                        </td>
                                            </tr>
                                            <tr>
                                                <td valign="top"><i className="fas fa-fax" /></td>
                                                <td>
                                                    +62 21 2708 3636
                                        </td>
                                            </tr>
                                            <tr>
                                                <td valign="top"><i className="fas fa-envelope" /></td>
                                                <td>
                                                    info.sreeya@sreeyasewu.com
                                        </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="1">
                        </Col>
                        <Col md="5">
                            <Row>
                                <Col sm="6">
                                    <h5 style={{ marginTop: '0' }} className="footer-title-link">Halal Sreeya</h5>
                                    <ul className="footer-link">
                                        <li>
                                            <Link to={{ pathname: '/produksi-halal', state: { prevPath: window.location.pathname } }}>
                                                Produksi Halal
                                    </Link>
                                        </li>
                                        {/* <li>
                                            <Link to={{ pathname: '/halal-tracker', state: { prevPath: window.location.pathname } }}>
                                                Halal Tracker
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link to={{ pathname: '/sistem-jaminan-halal', state: { prevPath: window.location.pathname } }}>
                                                Sistem Jaminan Halal
                                    </Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: '/faq', state: { prevPath: window.location.pathname } }}>
                                                FAQ
                                    </Link>
                                        </li>
                                        <li>
                                            <a href='https://www.sreeyasewu.com/en/home'>
                                                Tentang Kami
                                    </a>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: '/hubungi-kami', state: { prevPath: window.location.pathname } }}>
                                                Hubungi Kami
                                    </Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col sm="6">

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row> */}
                    {/* <nav className="footer-nav">
                        <ul>
                        <li>
                            <a
                            href="https://www.sreeyasewu.com/en/home"
                            target="_blank"
                            >
                            Sierad Produce
                            </a>
                        </li>
                        <li>
                            <a
                            href="https://www.sieradproduce.com/id-news/2"
                            target="_blank"
                            >
                            Berita
                            </a>
                        </li>
                        </ul>
                    </nav> */}
                    <div className="credits" style={{ display: 'block', textAlign: 'center' }}>
                        <span className="copyright" style={{ margin: 'auto !important' }}>
                            © {new Date().getFullYear()}, All Rights Reserved by PT. Sreeya Sewu Indonesia Tbk
                        </span>
                    </div>
                    {/* </Row> */}
                </Container>
            </footer>
        </div>
    );
}

export default DemoFooter;
