import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import landing from "../containers/LandingPage";
import restaurant from "../containers/Restaurant/Restaurant";
import restaurantv2 from "../containers/Restaurant/Restaurantv2";
import Login from "../Login.js";
import cms from "../containers/cms";

// import {history} from './helpers';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Routes = () => (
    <Switch>
        {/*<Route exact path="/" component={homepages}/>*/}
        <Route path="/produksi-halal" component={landing} />
        <Route path="/sistem-jaminan-halal" component={landing} />
        <Route path="/lacak-ayam" component={landing} />
        <Route path="/hubungi-kami" component={landing} />
        <Route path="/faq" component={landing} />
        <Route path="/old" component={landing} />
        {/* <Route path="/restaurant" component={restaurantv2} /> */}
        <Route path="/restaurant" component={restaurant} />
        {/* <Route exact path="/halal-tracker" component={landing} /> */}
        <Route path="/login" component={Login} />
        <Route path="/cms" component={cms} />
        {/* <Route path="/admin/overview" component={dashboard}/>
            <Route path="/admin/mitra" component={dashboard}/>
            <Route path="/admin/komoditas" component={dashboard}/>
            <Route path="/admin/petani" component={dashboard}/>
            <Route path="/admin/chain" component={dashboard}/>
            <Route path="/admin/qrcode" component={dashboard}/>
            <Route path="/admin/transaksi" component={dashboard}/>
            <Route path="/admin/pemasok" component={dashboard}/>
            <Route path="/admin/user_management" component={dashboard}/>
            <Route path="/admin/user_management_mitra" component={dashboard}/>
            <Route path="/admin/mitra_management" component={dashboard}/>
            <Route path="/admin/partners_management" component={dashboard}/> */}
        {/* <Redirect from='/admin' to='/admin/overview' /> */}

        {/* <Redirect from='/restaurant' to='/halal-tracker' /> */}
        <Redirect from='/' to='/produksi-halal' />
    </Switch>
);

export default Routes;