/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
// reactstrap components

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
// import IndexHeader from "../components/Headers/IndexHeader.js";
// import DemoFooter from "../components/Footers/DemoFooter.js";
import Route from "../../../Route/route-landing";

import "../assets/css/bootstrap.min.css";
import "../assets/scss/paper-kit.scss";
import "../assets/demo/demo.css";
import "../assets/css/custom-style.css";
import "swiper/swiper-bundle.css";


import { LanguageActions } from "../../../redux/actions";

class Index extends React.Component {
  // document.documentElement.classList.remove("nav-opens");
  // React.useEffect(() => {
  //   document.body.classList.add("index");
  //   return function cleanup() {
  //     document.body.classList.remove("index");
  //   };
  // });
  constructor(props) {
    super(props);
    this.state = {
      lang: 'indo'
    }
  }



  handleLang = (lang) => {
    const { dispatch } = this.props;
    dispatch(LanguageActions.changeLanguage(lang));
    // IndexNavbar(lang)
    this.setState({
      lang: lang
    })
  }


  render() {

    return (
      <>
        <IndexNavbar lang={this.state.lang} handleLang={this.handleLang} />
        <Route />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { language } = state;
  return {
    language
  };
};

// export default Index;
export default connect(mapStateToProps)(Index);
