import React from "react";
import {connect} from 'react-redux';

import { EditorState, convertToRaw,ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FormGroup,Label,Input,Button} from 'reactstrap';
import {Link} from "react-router-dom";
import {withRouter} from 'react-router';
// reactstrap components

import {FaqActions,LoadingActions} from "../../../redux/actions";

class Index extends React.Component {
    state = {
        editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML('')
            )
        ),
        question:'',
    }
    onChangeQuestion = (event) => {
        this.setState({
            question:event.target.value
        })
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };
    onSubmit = async()=>{
        const {dispatch} = this.props;
        await dispatch(LoadingActions.changeLoading(true));
        let payload = {
            number:'',
            title:this.state.question,
            content:draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        }
        let response = await dispatch(FaqActions.FaqAdd(payload));
        if(response){
            if(!response.data.error){
                this.props.history.push('/cms/faq');
                await dispatch(LoadingActions.changeLoading(false));
            } else {
                await dispatch(LoadingActions.changeLoading(false));
            }
        } else {
            await dispatch(LoadingActions.changeLoading(false));
        }
    }
    render(){
        const { editorState } = this.state;
        return (
            <div>
                <div className="cms-container-content">
                    <div style={{display:'flex',marginBottom:'2rem'}}>
                        <h4 style={{flex:'1'}}>Tambah FAQ</h4>
                    </div>
                    <div>
                        <FormGroup>
                            <Label for="exampleEmail">Pertanyaan</Label>
                            <Input
                                type="text"
                                name="pertanyaan"
                                id="exampleEmail"
                                placeholder="Tuliskan Pertanyaan"
                                value={this.state.question}
                                onChange={this.onChangeQuestion}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Jawaban</Label>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={this.onEditorStateChange}
                            />
                            {/* <textarea
                                disabled
                                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                            /> */}
                        </FormGroup>
                        <div style={{textAlign:'center'}}>
                            <br/>
                            <Link to="/cms/faq"><Button style={{margin:'8px'}}><i className='fas fa-reply'></i> Kembali</Button></Link>
                            <Button color="primary" style={{margin:'8px'}} onClick={this.onSubmit}>+ Tambahkan</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  const {language} = state;
  return {
    language
  };
};

// export default Index;
export default withRouter(connect(mapStateToProps)(Index));
