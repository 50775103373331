import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SistemJaminanHalal from "../containers/LandingPage/views/SistemJaminanHalal";
import ProduksiHalal from "../containers/LandingPage/views/ProduksiHalal";
import HalalBlockchain from "../containers/LandingPage/views/HalalBlockchain";
import HubungiKami from "../containers/LandingPage/views/HubungiKami";
import Faq from "../containers/LandingPage/views/Faq";
import Restaurant from "../containers/Restaurant/Restaurant";

// import {history} from './helpers';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Routes = () => (
    <Switch>
        <Route exact path="/sistem-jaminan-halal" component={SistemJaminanHalal} />
        <Route path="/old" component={ProduksiHalal} />
        <Route path="/hubungi-kami" component={HubungiKami} />
        <Route path="/faq" component={Faq} />
        <Route exact path="/produksi-halal"
            render={(props) => (
                <HalalBlockchain {...props} mobile-view={true} />
            )} />
        <Redirect from='/' to='/halal-tracker' />
    </Switch>
);

export default Routes;