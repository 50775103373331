import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { connect } from 'react-redux';
// import Zoom from 'react-reveal/Zoom';
// import Fade from 'react-reveal/Fade';
import 'react-vertical-timeline-component/style.min.css';

import { ProsesActions } from "../../redux/actions";
import { Collapse } from 'reactstrap';
import axios from 'axios';
import '../../assets/css/restaurant-css.css';

const mobileTreshold = 767;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            process: [],
            search: '',
            loading: false,
            modal: false,
            selectedImage: '',

            open: "",
            width: undefined,
            height: undefined,

            partners: [],

            image: [
                {
                    img: null,
                    narasi: "",
                },
                {
                    img: require('../../assets/img/01-compress.gif').default,
                    narasi: "Ayam yang masih hidup diperiksa kesehatannya oleh dokter hewan untuk memastikan ayam tersebut sehat dan bebas dari penyakit."
                },
                {
                    img: require('../../assets/img/02-compress.gif').default,
                    narasi: "Ayam ditimbang untuk memastikan keseragaman bobot badan ayam."
                },
                {
                    img: require('../../assets/img/03-compress.gif').default,
                    narasi: "Ayam diistirahatkan untuk menghindari stress pada ayam dan menjaga kualitas daging pada saat dipotong."
                },
                {
                    img: null,
                    narasi: "",
                },
                {
                    img: null,
                    narasi: "",
                },
                {
                    img: require('../../assets/img/04-compress.gif').default,
                    narasi: "Ayam dipotong oleh Juru Sembelih Halal (JULEHA) yang sudah disertifikasi oleh MUI dan prosesnya sudah sesuai Standar Sertifikasi Penyembelihan Halal .  Dimana ayam dalam kondisi hidup sebelum dipotong dan memotong tepat di tiga saluran yaitu pernafasan, makanan dan darah.  Informasi dan data ini bisa diakses secara langsung oleh semua orang."
                },
                {
                    img: null,
                    narasi: "",
                }, {
                    img: null,
                    narasi: "",
                }, {
                    img: null,
                    narasi: "",
                }, {
                    img: null,
                    narasi: "",
                }, {
                    img: null,
                    narasi: "",
                },
                {
                    img: require('../../assets/img/05-compress.gif').default,
                    narasi: "Ayam diseleksi dan ditimbang untuk menjamin kualitas dan mutu berat sesuai standar."
                },
                {
                    img: require('../../assets/img/06-compress.gif').default,
                    narasi: "Ayam siap untuk didistribusikan dengan menggunakan kendaraan khusus berpendingin agar tetap  aman, sehat, utuh dan halal untuk mempertahankan mutu / kualitas dan keamanan daging ayam."
                },
            ]
        }
    }
    handleResize = () => {
        this.setState({
            width: window.innerWidth,
        });
        this.setPartnersHeight()
    };

    setPartnersHeight = () => {
        const height = document.getElementById('restaurant-partners').clientHeight;
        this.setState({ height });
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.setState({ initialOpenFilter: true, openFilter: false });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    };

    handleChange = (event) => {
        this.setState({
            search: event.target.value
        })
    }
    componentWillMount() {

        document.title = 'Teknologi Ketelusuran Halal Blockchain untuk Restoran | PT Sreeya Sewu Indonesia Tbk.';
        this.handleSubmitSearch("latest");
        this.getDataPartners();
    }

    handleSubmitSearch = async (key) => {
        this.setState({
            loading: true
        })
        let search = key;
        if (search === "null") {
            search = this.state.search
        }
        const { dispatch } = this.props;
        let response = await dispatch(ProsesActions.getListProses(search));
        if (response) {
            if (!response.data.error) {
                let data = response.data.dataLocal;
                data.sort((a, b) => (a.id > b.id) ? 1 : -1)
                for (let i = 0; i < data.length; i++) {
                    let temp = [];
                    for (let j = 0; j < data[i].processImages.length; j++) {
                        temp.push({
                            src: data[i].processImages[j].src,
                            altText: data[i].processImages[j].altText,
                            caption: ''
                        })
                    }
                    data[i].processImages = temp;
                }
                this.setState({
                    process: data,
                    loading: false
                })
            } else {
                this.setState({
                    process: [],
                    loading: false
                })
            }
        } else {
            this.setState({
                process: [],
                loading: false
            })
        }
    }

    dateFormatting = (date) => {
        const today = new Date(date)
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()
        let monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
        const finalDate = day + " " + monthName[month] + " " + year
        return finalDate
    }

    dateIDFormatting = (date) => {
        const today = new Date(date)
        const year = today.getFullYear()
        const month = ("0" + (today.getMonth() + 1)).slice(-2)
        const day = ("0" + today.getDate()).slice(-2)
        const finalDate = year + "" + month + "" + day
        return finalDate
    }

    toggle = (image) => {
        this.setState({
            modal: !this.state.modal,
            selectedImage: image,
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.location.state) {
            if (this.props.location.pathname !== prevProps.location.state.prevPath) {
                window.scrollTo(0, 0)
            }
        }
    }

    setClick = (number) => {
        this.setState({
            open: number
        })
    }

    getPartners = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SPREADSHEETS_LINK);
            return response;
        } catch (error) {
            return Promise.reject(error.response);
        }
    }

    getDataPartners = async () => {
        let response = await this.getPartners();
        if (response) {
            if (response.data) {
                if (response.data.feed) {
                    if (response.data.feed.entry) {
                        // console.log(response.data.feed.entry)
                        this.setState({
                            partners: response.data.feed.entry
                        })
                        this.setPartnersHeight();
                    } else {
                        this.setState({
                            partners: []
                        })
                    }
                } else {
                    this.setState({
                        partners: []
                    })
                }
            } else {
                this.setState({
                    partners: []
                })
            }
        } else {
            this.setState({
                partners: []
            })
        }
    }

    render() {
        const { process } = this.state;
        // console.log(process)
        return (
            <div className="restaurant">
                <div className="main">
                    <section className='section-lg section section-process' style={{ paddingTop: '2.5%' }}>
                        <div className='container'>
                            <h1 style={(this.state.width > mobileTreshold) ? { fontWeight: '800', margin: '2rem 0 2rem 0', fontSize: '340%' } : { fontWeight: '800', margin: '2rem 0 2rem 0', fontSize: '250%' }}>
                                Ayam ini sudah terjamin kehalalannya melalui Teknologi Halal Blockchain
                            </h1>
                            {/* 350% */}
                            <Row>
                                <Col md='6' lg='6'>
                                    <br /><br />
                                    <h3 style={{ fontWeight: '700', margin: '1rem 0' }}>Apa itu Teknologi Halal Blockchain?</h3>
                                    <p style={{ fontWeight: '400' }}>
                                        Halal blockchain merupakan sistem pemotongan ayam halal yang bisa diakses oleh semua orang secara digital.
                                    </p>
                                    <p style={{ fontWeight: '400' }}>
                                        Teknologi ini menyajikan data proses pemotongan ayam yang tidak dapat diubah ataupun dihapus untuk memastikan bahwa ayam telah melalui prosedur halal dari awal hingga akhir. Sehingga dipastikan bahwa ayam ini sudah thayyib (sehat, aman, bernutrisi dan berkualitas baik).
                                    </p>
                                    <p style={{ fontWeight: '400' }}>
                                        Hingga saat ini, PT Sreeya Sewu Indonesia, Tbk merupakan satu-satunya perusahaan unggas yang telah menerapkan teknologi Halal Blockchain di Indonesia.
                                    </p>
                                </Col>
                                <Col md='6' lg='6' style={{ padding: 0 }}>
                                    {(this.state.width <= 990 && this.state.width >= 768) ?
                                        <div style={{ marginBottom: '120px' }}></div>
                                        :
                                        ""
                                    }
                                    <img style={{ width: '100%' }} src={require('../../assets/img/07-resize.png').default} alt="family-dinner" />
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <div style={{ position: 'relative', width: '100%', backgroundColor: 'transparent', marginBottom: '-1px' }}>
                        <div style={{ position: 'relative', zIndex: '5', lineHeight: '0px' }}>
                            <svg viewBox="0 0 40 5" width="100%" >
                                {/* <path fill="#008641" d="M0 0 C25 6 25 6 50 0 L50 20 0 20"></path> */}
                                <path fill="#008641" d="M 0 0 C 10 5 30 5 40 0 L40 20 0 20"></path>
                            </svg>
                        </div>
                    </div>
                    <section className='section-lg section section-process' style={{ backgroundColor: '#008641', paddingBottom: '70px' }}>
                        <div className='container'>
                            <h1 style={{ fontWeight: '800', margin: '1rem 0', color: 'white', }}>Bagaimana Prosesnya?</h1>

                            {this.state.process.map((proses, idx) => {
                                if (idx === 1 || idx === 2 || idx === 3 || idx === 6 || idx === 12 || idx === 13) {
                                    return (
                                        <Row key={idx}>
                                            <Col md='6' lg='6' style={(this.state.width > mobileTreshold) ? {} : { padding: '0 32px' }}>
                                                <div className={(this.state.open === idx) ? "proses-main active" : "proses-main"}>
                                                    {/* <div className={'proses-title'}>Antemortem</div> */}
                                                    <div className={'proses-title'} onClick={() => { this.setClick((this.state.open === idx) ? "" : idx) }}>
                                                        {(idx !== 12) ? proses.processName : "Proses Seleksi dan Penimbangan Ayam"}
                                                    </div>
                                                    <Collapse isOpen={(this.state.open === idx) ? true : false}>
                                                        <div className="proses-content">
                                                            <div className="public-process-content-idtanggal">
                                                                <Row>
                                                                    <Col lg='6'>
                                                                        {/* <p>Nomor ID: {(process.length>0)?this.dateIDFormatting(process[1].date):''}</p> */}
                                                                        <p>Nomor ID: {(process.length > 0) ? this.dateIDFormatting(proses.date) : ''}</p>
                                                                    </Col>
                                                                    <Col lg='6'>
                                                                        {/* <p>Tanggal: {(process.length>0)?this.dateFormatting(process[1].date):''}</p> */}
                                                                        <p>Tanggal: {(process.length > 0) ? this.dateFormatting(proses.date) : ''}</p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {(proses.data.length > 0) ?
                                                                <div className="public-process-content-value">

                                                                    {(proses.processCodename !== "slaughter") ?
                                                                        (
                                                                            <Row>
                                                                                {proses.data.map((list, idx) => {
                                                                                    if (list.dataType === "text") {
                                                                                        return (
                                                                                            <Col lg='9' key={idx}>
                                                                                                <div className="public-process-content-value-box">
                                                                                                    <p className="value">{list.value}</p>
                                                                                                    <p className="key">{list.dataName}</p>
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    } else if (list.dataType === "number") {
                                                                                        return (
                                                                                            <Col lg='9' key={idx}>
                                                                                                <div className="public-process-content-value-box">
                                                                                                    <p className="value">{Math.round(list.value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} {list.unit}</p>
                                                                                                    <p className="key">
                                                                                                        {/* {list.dataName} */}
                                                                                                        {(idx === 2) ? list.dataName : "Total keseluruhan berat ayam yang dipotong pada hari ini"}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                })}
                                                                            </Row>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Row>
                                                                                {proses.data.map((list, idx) => {
                                                                                    if (idx === 0) {
                                                                                        if (list.dataType === "text") {
                                                                                            return (
                                                                                                <Col lg='9' key={idx}>
                                                                                                    <div className="public-process-content-value-box">
                                                                                                        <p className="value">{list.value}</p>
                                                                                                        <p className="key">{list.dataName}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            )
                                                                                        } else if (list.dataType === "number") {
                                                                                            return (
                                                                                                <Col lg='9' key={idx}>
                                                                                                    <div className="public-process-content-value-box">
                                                                                                        <p className="value">{Math.round(list.value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} {list.unit}</p>
                                                                                                        <p className="key">{list.dataName}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            )
                                                                                        } else {
                                                                                            return null
                                                                                        }
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                })}
                                                                                <div key={idx} style={{ textAlign: 'left' }}>
                                                                                    <br />
                                                                                    <h5 style={{ color: '#008236', fontSize: '16px', fontWeight: 'bold', marginLeft: '1rem' }}>Sertifikat penyembelih</h5>
                                                                                    <Row>
                                                                                        {proses.data.map((list, idx) => {
                                                                                            if (idx !== 0) {
                                                                                                return (
                                                                                                    <Col lg="3" md="4" sm="4" xs="6" key={idx} style={{ textAlign: 'center', marginTop: '1rem', padding: '0.5rem' }}>
                                                                                                        {(list.photo) ?
                                                                                                            <img className='img-thumbnail' style={{ width: '75%' }} src={list.photo} alt={"photo-" + idx} />
                                                                                                            :
                                                                                                            <img style={{ width: '100%' }} src={require('../LandingPage/assets/img/default-avatar.png').default} alt="default" />
                                                                                                        }
                                                                                                        <br />
                                                                                                        <p style={{ color: '#008236', fontSize: '14px', fontWeight: 'bold', margin: '0' }}>
                                                                                                            {list.name}
                                                                                                        </p>
                                                                                                        <p style={{ color: '#828282', fontSize: '12px', margin: '0' }}>
                                                                                                            No. Sertifikat
                                                                                            </p>
                                                                                                        <p onClick={() => this.toggle(list.sertifikatImage)} className='sertifikat-penyembelih' style={{ color: '#828282', fontSize: '12px', margin: '0' }}>
                                                                                                            <u>{list.noSertifikat}</u>
                                                                                                        </p>
                                                                                                    </Col>
                                                                                                );
                                                                                            } else {
                                                                                                return null
                                                                                            }
                                                                                        })}
                                                                                    </Row>
                                                                                </div>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </div>
                                                                : ''}
                                                            {/* {(process.length>0)?
                                                    <div className="public-process-content-button">
                                                        <div>
                                                            <a style={{margin:'0.8rem 0'}} target="_blank" href={"https://blockexplorer.sieradproduce.com/traces?address="+process[1].blockchainAddress+"&version="+this.dateIDFormatting(process[1].date)} >Telusuri dalam Blockchain</a>
                                                        </div>
                                                    </div>
                                                    :""} */}
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* <Collapse isOpen={(this.state.open === idx && this.state.width>mobileTreshold)?true:false}>
                                            <div style={{width:'100%', textAlign:'center'}}>
                                                <img style={{width:'80%', marginTop:'3rem'}} src={require('../../assets/img/Halal Blockchain by Sreeya Sewu.png').default} alt="sreeya-blockchain"/>
                                            </div>
                                        </Collapse> */}
                                            </Col>
                                            <Col md='6' lg='6' style={(this.state.width > mobileTreshold) ? { paddingLeft: '0px', textAlign: 'left' } : { padding: '4px', textAlign: 'center' }}>
                                                <Collapse isOpen={(this.state.open === idx) ? true : false} style={{ marginTop: '-1rem' }}>
                                                    <div className="proses-image">
                                                        <img src={this.state.image[idx].img} alt="chicken" />
                                                        <div>
                                                            <p style={{ fontWeight: '400', color: '#1c8938' }}>
                                                                {this.state.image[idx].narasi}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                                <Collapse isOpen={(this.state.open === "" && idx === 3 && this.state.width > mobileTreshold) ? true : false} style={{ marginTop: '-1rem' }}>
                                                    <div style={{ position: 'absolute', width: '100%', textAlign: 'center' }}>
                                                        <img style={{ width: '80%', marginTop: '3rem' }} src={require('../../assets/img/Halal Blockchain by Sreeya Sewu.png').default} alt="sreeya-blockchain" />
                                                    </div>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    )
                                } else {
                                    return null
                                }
                            })}


                        </div>
                    </section>
                    <div style={{ position: 'relative', width: '100%', backgroundColor: '#b2c81c', marginTop: '-6px' }}>
                        <div style={{ position: 'relative', zIndex: '5', lineHeight: '0px' }}>
                            <svg viewBox="0 0 40 3" width="100%" >
                                {/* <path fill="#008641" d="M0 7.5 C25 1 25 1 50 7.5 L50 -2.5 0 -2.5 "></path> */}
                                <path fill="#008641" d="M 0 3 C 10 -1 30 -1 40 3 L40 -4 0 -4"></path>
                            </svg>
                        </div>
                    </div>
                    <section className='section-lg section section-process' style={{ backgroundColor: '#b2c81c', marginTop: '-1px', paddingTop: "0px", paddingBottom: ((this.state.height / 2)) + "px" }}>
                        <div className='container'>
                            <Row>
                                <Col md='6' lg='6' style={{ paddingTop: '60px' }}>
                                    <h1 style={{ fontWeight: '700', margin: '1rem 0', color: '#1c8938', fontSize: '32px' }}>
                                        Kami berkomitmen untuk selalu menerapkan prosedur Halal untuk menghasilkan daging ayam yang berkualitas.
                                    </h1>
                                    <p style={{ fontWeight: '400', color: '#1c8938' }}>
                                        Penggunaan Teknologi Halal Blockchain merupakan salah satu bentuk komitmen kami untuk menghasilkan daging ayam yang sudah menerapkan prosedur halal, pelaksanaan <i>Biosecurity</i> (Keamanan Hayati) dan <i>Animal Health Welfare</i> (Kesejahteraan dan Kesehatan Hewan).
                                    </p>
                                </Col>
                                <Col md='6' lg='6' style={{ padding: 0 }}>
                                    {(this.state.width <= 990 && this.state.width >= 768) ?
                                        <div style={{ marginBottom: '120px' }}></div>
                                        :
                                        ""
                                    }
                                    <img style={{ width: '100%' }} src={require('../../assets/img/08-resize.png').default} alt="sreeya-squad" />
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <div className="restaurant-partners" id="restaurant-partners">
                        <div className='container' style={{ textAlign: 'center' }}>
                            <div style={{ maxWidth: '75%', margin: 'auto', width: 'auto', backgroundColor: 'white', borderRadius: '1rem', padding: '1rem 0.5rem 0.5rem 0.5rem', textAlign: 'center' }}>
                                <h5 style={{ fontWeight: '400', margin: '0.5rem 0 1.5rem 0' }}>Partners :</h5>
                                <div>
                                    {this.state.partners.map((list, idx) => {
                                        return (
                                            <img key={idx} style={{ width: '70px', height: '70px', margin: '8px' }} src={list.content.$t.substring(5)} alt={list.title.$t} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='section-lg section section-process' style={{ backgroundColor: '#008641', paddingBottom: '32px', paddingTop: ((this.state.height / 2) + 40) + "px" }}>
                        <div className='container'>
                            <Row>
                                <Col sm="3" md='2' lg='2'>
                                    <div style={(this.state.width > 575) ? { color: 'white', lineHeight: '57px' } : { color: 'white', lineHeight: '57px', textAlign: 'center' }}>
                                        © {new Date().getFullYear()}
                                    </div>
                                </Col>
                                <Col sm="9" md='10' lg='10'>
                                    <div style={(this.state.width > 575) ? { color: 'white', textAlign: 'right', fontSize: '14px' } : { color: 'white', fontSize: '14px', textAlign: 'center' }}>
                                        Dapatkan informasi lebih tentang Halal Blockchain di {(this.state.width > 575) ? "" : <br />}
                                        <a href="http://sreeyasewu.com"><img style={{ width: '150px', margin: '10px' }} src={require('../LandingPage/assets/img/sreeya/oie_transparent (1).png').default} alt="logo sreeya" /></a>
                                        <a href="http://halaltracker.sreeyasewu.com/produksi-halal?id=latest"><img style={{ width: '150px', margin: '10px' }} src={require('../../assets/img/halal blockchain white.png').default} alt="logo sreeya halal" /></a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody style={{ padding: '1rem' }}>
                        <img style={{ width: '100%' }} src={this.state.selectedImage} alt={"sertifikat-penyembelih"} />
                    </ModalBody>
                    <ModalFooter style={{ padding: '1rem' }}>
                        <Button color="secondary" onClick={this.toggle}>Keluar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

// export default withRouter((App));
const mapStateToProps = (state) => {
    const { language } = state;
    return {
        language
    };
};
export default withRouter(connect(mapStateToProps)(App));
