import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
    
class App extends Component {

    render() {
        return (
            <div>
                <section className='section-lg section section-subscribe'>
                    <div className='container'>
                        <div className="subscribe-content">
                            {/* <h3 style={{fontWeight:'bold',margin:'1rem 0'}}>Dapatkan informasi yang lebih tentang Kami</h3>
                            <p style={{fontWeight:'400'}}>
                                Tuliskan alamat surel Anda untuk mendapatkan informasi lebih lanjut mengenai inisiatif yang kami lakukan atau informasi lebih lanjut lainnya 
                            </p> */}
                            <h3 style={{fontWeight:'bold',margin:'1rem 0'}}>Dapatkan informasi yang lebih tentang Kami</h3>
                            <p style={{fontWeight:'400'}}>
                                Klik pada tombol di bawah ini dan tuliskan pertanyaan anda kepada kami
                            </p>
                            <br/>
                            <Link to="/hubungi-kami">
                            <button style={{
                                border: '#fff 2px solid',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                margin: '1rem 0',
                                padding: '0.5rem 2rem',
                                borderRadius: '1rem',
                            }}>HUBUNGI KAMI</button>
                            </Link>
                            {/* <div className="subscribe-form">
                                <input type='text' placeholder="Masukkan Email Anda" disabled/>
                                <button>Kirim</button>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

// export default withRouter((App));
const mapStateToProps = (state) => {
    const {language} = state;
    return {
        language
    };
  };
export default withRouter(connect(mapStateToProps)(App));
