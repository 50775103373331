import React from "react";
import {connect} from 'react-redux';
// reactstrap components
import { Button,Table, Modal, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import { EditorState, convertToRaw,ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ProsesActions,LoadingActions} from "../../../redux/actions";

import Dropzone from 'react-dropzone'

class Index extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
        list: [],
        selectedId:'',
        processName:'',
        description:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML('')
          )
        ),
        knowledgePoint:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML('')
          )
        ),
        modal:false,
        modalDesc:false,
        modalKp:false,
        modalHapus:false,
        image:null,
        listGambar:[],
        idGambar:'',
      }
    }
	componentWillMount(){
        this.getProses();
    }
	getProses= async ()=>{
    const {dispatch} = this.props;
    await dispatch(LoadingActions.changeLoading(true));
    let response = await dispatch(ProsesActions.getListJustProses());
		if(response){
			if(!response.data.error){
        let data = response.data.dataLocal;
        this.setState({
          list:data
        })
        await dispatch(LoadingActions.changeLoading(false));
      } else {
        this.setState({
          list:[]
        })
        await dispatch(LoadingActions.changeLoading(false));
      }
		} else {
      this.setState({
        list:[]
      })
      await dispatch(LoadingActions.changeLoading(false));
    }
  }
  
	toggle = (data) => {
		if(data){
      if(data.processImages){
        this.setState({
          modal:!this.state.modal,
          selectedId:data.id,
          image:null,
          listGambar:data.processImages
        })
      } else {
        this.setState({
          modal:!this.state.modal,
          selectedId:data.id,
          image:null,
          listGambar:[]
        })
      }
		} else {
			this.setState({
				modal:!this.state.modal,
				selectedId:'',
        image:null,
        listGambar:[],
			})
		}
  }
  toggleHapus = (id) => {
		if(id){
			this.setState({
				modalHapus:!this.state.modalHapus,
				idGambar:id,
			})
		} else {
			this.setState({
				modalHapus:!this.state.modalHapus,
				idGambar:'',
			})
		}
  }
  toggleDesc = (data) => {
		if(data && data!=='close'){
			this.setState({
				modalDesc:!this.state.modalDesc,
				selectedId:data.id,
				description:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.description||"")
          )
        ),
        knowledgePoint:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.knowledgePoint||"")
          )
        ),
			})
		} else {
			this.setState({
				modalDesc:false,
				selectedId:'',
				description:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML("")
          )
        ),
        knowledgePoint:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML("")
          )
        ),
			})
		}
  }
  toggleKp = (data) => {
		if(data && data!=='close'){
			this.setState({
				modalKp:!this.state.modalKp,
				selectedId:data.id,
				description:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.description||"")
          )
        ),
        knowledgePoint:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.knowledgePoint||"")
          )
        ),
			})
		} else {
			this.setState({
				modalKp:false,
				selectedId:'',
				description:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML("")
          )
        ),
        knowledgePoint:EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML("")
          )
        ),
			})
		}
	}

	onDrop = (acceptedFiles) => {
		this.setState({
			image:acceptedFiles[0]
		})
	}

	handleSubmitGambar = async() => {
    const {dispatch} = this.props;
    await dispatch(LoadingActions.changeLoading(true));
		const {selectedId,image,} = this.state;
		let altText = 'foto-'+selectedId;
		const fd = new FormData();
		fd.append('uploadedImage',image);
		fd.append('altText',altText);
		let response = await dispatch(ProsesActions.addImageProses(selectedId,fd));
		if(response){
			if(!response.data.error){
        this.toggle();
        this.getProses();
        await dispatch(LoadingActions.changeLoading(false));
      } else {
        await dispatch(LoadingActions.changeLoading(false));
      }
		} else {
      await dispatch(LoadingActions.changeLoading(false));
    }
  }

  handleSubmitHapusGambar = async() => {
    const {dispatch} = this.props;
    const {idGambar} = this.state;
    await dispatch(LoadingActions.changeLoading(true));
		let response = await dispatch(ProsesActions.deleteImageProses(idGambar));
		if(response){
      if(!response.data.error){
        this.toggleHapus();
        this.toggle();
        this.getProses();
        await dispatch(LoadingActions.changeLoading(false));
      } else {
        await dispatch(LoadingActions.changeLoading(false));
      }
		} else {
      await dispatch(LoadingActions.changeLoading(false));
    }
  }
  
  handleSubmit = async() => {
		const {dispatch} = this.props;
    const {selectedId,description,knowledgePoint} = this.state;
    await dispatch(LoadingActions.changeLoading(true));
		let payload = {
      description:draftToHtml(convertToRaw(description.getCurrentContent())),
      knowledgePoint:draftToHtml(convertToRaw(knowledgePoint.getCurrentContent()))
    }
		let response = await dispatch(ProsesActions.updateProses(selectedId,payload));
		if(response){
      if(!response.data.error){
        this.toggleKp('close');
        this.toggleDesc('close');
        this.getProses();
        await dispatch(LoadingActions.changeLoading(false));
      } else {
        await dispatch(LoadingActions.changeLoading(false));
      }
		} else {
      await dispatch(LoadingActions.changeLoading(false));
    }
	}

	onEditorStateChange = (description) => {
    this.setState({
      description,
    });
  };
  onEditorStateChangeKp = (knowledgePoint) => {
    this.setState({
      knowledgePoint,
    });
  };
	
	render(){
		return (
			<>
				<div className='cms-container-content'>
					<h4 style={{marginBottom:'2rem'}}>Pengaturan Konten Proses</h4>
					<div style={{width:'100%',overflow:'auto'}}>
						<Table>
							<thead>
								<tr>
								<th>#</th>
								<th>Nama Proses</th>
                <th>Deskripsi</th>
								<th>Keypoint</th>
								<th>Gambar</th>
								</tr>
							</thead>
							<tbody>
								{this.state.list.map((list,idx)=>{
									return (
										<tr key={idx}>
											<th scope="row">{idx+1}</th>
											<td>{list.processName}</td>
											<td><Button style={{fontSize:'12px',margin:'4px'}} onClick={()=>this.toggleDesc(list)}>Atur Deskripsi</Button></td>
											<td><Button style={{fontSize:'12px',margin:'4px'}} onClick={()=>this.toggleKp(list)}>Atur Keypoint</Button></td>
											<td>
                        <Button color="success" style={{fontSize:'12px',margin:'4px'}} onClick={()=>this.toggle(list)}>Atur Gambar</Button>
                      </td>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</div>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
						<ModalBody style={{padding:'1rem'}}>
							<h4 style={{paddingTop:'0'}}>Atur Gambar</h4>
							<br/>
							<div className="uploadzone">
								<Dropzone onDrop={this.onDrop}>
									{({getRootProps, getInputProps}) => (
										<div {...getRootProps()}>
										<input {...getInputProps()} />
											
											{(this.state.image)?
												(this.state.image.path)
												:
												('Click or Drag Image here to upload a file!')
											}
										</div>
									)}
								</Dropzone>
							</div>
              <div style={{textAlign:'right',margin:'12px 0'}}>
                <Button color="primary" onClick={this.handleSubmitGambar}>Tambahkan</Button>{' '}
              </div>
              <hr/>
              <div>
                <table style={{width:'100%'}}>
                  <tbody>
                    {(this.state.listGambar.map((image,idx)=>{
                      return(
                        <tr key={idx}>
                          <td>
                            {/* <img style={{maxHeight:'200px',maxWidth:'100%'}} src={'https://lh3.googleusercontent.com/u/0/d/'+image.src+'=w1366-h657-iv1'} alt={image.altText}/> */}
                            <img style={{maxHeight:'200px',maxWidth:'100%'}} src={image.src} alt={image.altText}/>
                          </td>
                          <td style={{textAlign:'center'}}>
                            <Button color="danger" onClick={()=>this.toggleHapus(image.id)}>Hapus</Button>
                          </td>
                        </tr>
                      )
                    }))}
                  </tbody>
                </table>
              </div>
						</ModalBody>
						<ModalFooter style={{padding:'1rem'}}>
            <Button color="secondary" onClick={this.toggle}>Batal</Button>
						</ModalFooter>
					</Modal>
          <Modal isOpen={this.state.modalDesc} toggle={this.toggleDesc}>
						<ModalBody style={{padding:'1rem'}}>
							<h4 style={{paddingTop:'0'}}>Ubah Deskripsi</h4>
							<br/>
							<FormGroup>
                <Editor
                  editorState={this.state.description}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </FormGroup>
						</ModalBody>
						<ModalFooter style={{padding:'1rem'}}>
						<Button color="primary" onClick={this.handleSubmit}>Ubah</Button>{' '}
						<Button color="secondary" onClick={this.toggleDesc}>Batal</Button>
						</ModalFooter>
					</Modal>
          <Modal isOpen={this.state.modalKp} toggle={this.toggleKp}>
						<ModalBody style={{padding:'1rem'}}>
							<h4 style={{paddingTop:'0'}}>Ubah Keypoint</h4>
              <span style={{fontSize:'12px', color:'gray'}}>Kosongkan jika memang tidak ada keypoint pada proses ini</span>
              <br/>
							<br/>
							<FormGroup>
                <Editor
                  editorState={this.state.knowledgePoint}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onEditorStateChangeKp}
                />
              </FormGroup>
						</ModalBody>
						<ModalFooter style={{padding:'1rem'}}>
						<Button color="primary" onClick={this.handleSubmit}>Ubah</Button>{' '}
						<Button color="secondary" onClick={this.toggleKp}>Batal</Button>
						</ModalFooter>
					</Modal>
          <Modal isOpen={this.state.modalHapus} toggle={this.toggleHapus}>
            <ModalBody style={{padding:'1rem'}}>
              <div style={{textAlign:'center'}}>
                <img style={{width:'100px'}} src={require('../assets/img/warning.png').default} alt="warning"/>
                <p style={{fontWeight:'400'}}>Apakah kamu yakin akan menghapus gambar ini?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{padding:'1rem'}}>
              <Button color="danger" onClick={this.handleSubmitHapusGambar}><i class='fas fa-trash-alt'></i> Hapus</Button>{' '}
              <Button onClick={this.toggleHapus}>Cancel</Button>
            </ModalFooter>
          </Modal>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
  const {language,loading} = state;
  return {
    language,
    loading
  };
};

// export default Index;
export default connect(mapStateToProps)(Index);
