import React, {Component} from 'react';
import {withRouter} from 'react-router';
// import DemoFooter from "../components/Footers/DemoFooter.js";
import FooterDark from "../components/Footers/FooterDark";
import { Row,Col,UncontrolledCollapse } from "reactstrap";
import {connect} from 'react-redux';

import {FaqActions} from "../../../redux/actions";
    
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search:'',
            // selectedRow:'',
            faq:[],
            searchFaq:[]
        }
    }

    // ShowAnswer=(selected)=>{
    //     if(selected===this.state.selectedRow){
    //         this.setState({
    //             selectedRow:""
    //         })
    //     } else {
    //         this.setState({
    //             selectedRow:selected
    //         })
    //     }
    // }

    handleSearch(e){
        this.setState({
            search:e.target.value
        })
    }
    searchFaq=()=>{
        const {search,faq} = this.state;

        let tempSearch = [];
        faq.filter(
            (row, index) => {
                if (row.title.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                    return tempSearch[index] = row;
                } else if (row.content.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                    return tempSearch[index] = row;
                } else {
                    return null;
                }
            }
        );

        this.setState({
            searchFaq:tempSearch
        })
    }

    componentWillMount(){
        this.getListFaq()
    }

    getListFaq= async ()=>{
        const {dispatch} = this.props;
        let response = await dispatch(FaqActions.getListFaq());
        if(response){
            if(!response.data.error){
                let data = response.data.faqs;
                data.sort((a, b) => (a.number > b.number) ? 1 : -1)
                this.setState({
                    faq:data,
                    searchFaq:data
                })
            } else {
                this.setState({
                    faq:[],
                    searchFaq:[]
                })
            }
        } else {
            this.setState({
                faq:[],
                searchFaq:[]
            })
        }
    }
    componentDidUpdate(prevProps,state) {
        if(state.faq.length === 0){
            if(prevProps.location.state){
                if (this.props.location.pathname !== prevProps.location.state.prevPath) {
                window.scrollTo(0, 0)
                }
            }
        }
    }

    render() {
        return (
            <div>
                <div className="main">
                    <section className='section-lg section section-search section-faq-search'>
                        <div className='container'>
                            <Row>
                                <Col md='6' style={{textAlign:'center'}}>
                                    <img style={{width:'70%'}} src={require('../assets/img/sierad/QnA-02.png').default} alt="chicken"/>
                                </Col>
                                {/* <Col md='1' style={{textAlign:'center'}}>
                                </Col> */}
                                <Col md='6'>
                                    <div className="section-search-content" style={{textAlign:'center'}}>
                                        <h2 style={{color:'white',marginTop:'0'}}>Butuh Bantuan?</h2>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                                        <div className="public-search" style={{alignItems: 'center',justifyContent: 'center'}}>
                                            <input type='text' placeholder="Cari pertanyaan..." value={this.state.search} onChange={(e)=>this.handleSearch(e)}/>
                                            <button onClick={this.searchFaq} style={{backgroundColor:'#008236', borderColor:'#008236'}}><i className='fas fa-search'></i></button>
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col md='12' style={{textAlign:'center', margin:'6% 0'}}>
                                    <h2 style={{color:'white',marginTop:'0'}}>Butuh Bantuan?</h2>
                                    <div className="public-search" style={{alignItems: 'center',justifyContent: 'center'}}>
                                        <input type='text' placeholder="Cari pertanyaan..." value={this.state.search} onChange={(e)=>this.handleSearch(e)}/>
                                        <button onClick={this.searchFaq} style={{backgroundColor:'#008236', borderColor:'#008236'}}><i className='fas fa-search'></i></button>
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                    </section>
                    <div style={{position:'absolute',width:'100%',marginTop:'-1px'}}>
                        <div className='container' style={{position:'relative', zIndex:'5', lineHeight:'0px'}}>
                            <svg viewBox="0 0 800 30">
                                <path fill="#e99600" d="M 0 0 Q 250 0 300 0 C 350 0 350 30 400 30 C 450 30 450 0 500 0 C 550 0 550 0 800 0 "></path>
                            </svg>
                        </div>
                    </div>
                    <section className='section-lg section' style={{backgroundColor: '#f4f4f4', padding:'100px 0px'}}>
                        <div className="container">
                            <Row>
                                <Col md="3">
                                    <h4 style={{fontWeight:'bold', marginBottom:'2rem',marginTop:'0'}}>Pertanyaan Paling Dicari</h4>
                                </Col>
                                {(this.state.searchFaq.length>0)?
                                    <Col md="9">
                                        {this.state.searchFaq.map((question,idx)=>{
                                            return (
                                                <div className="" key={idx}>
                                                    <div id={"faq"+idx} className="section-faq-questions">
                                                        <h5><div style={{width:'1rem',display:'inline-block'}}>&#8226;</div> {question.title}</h5>
                                                    </div>
                                                    <UncontrolledCollapse toggler={"faq"+idx}>
                                                        <div className={"section-faq-answer"}>
                                                            <div dangerouslySetInnerHTML={{ __html: question.content }} />
                                                        </div>
                                                    </UncontrolledCollapse >
                                                </div>
                                            )
                                        })}
                                    </Col>
                                :
                                    <Col md="9">
                                        <p style={{fontWeight:'400'}}>Gagal memuat pertanyaan.</p>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </section>
                    <FooterDark />
                </div>
            </div>
        )
    }
}

// export default withRouter((App));
const mapStateToProps = (state) => {
    const {language} = state;
    return {
        language
    };
  };
export default withRouter(connect(mapStateToProps)(App));
