import React, {Component} from 'react';
import {withRouter} from 'react-router';
import LandingPage from './views/Index.js';
import Login from '../../Login';
import jwt_decode from 'jwt-decode';

class Index extends Component {

    render() {
        const current_url = window.location.pathname;
        let Content = null;
        if (localStorage.getItem('access') === null) {
            Content = <Login/>;
        } else {
            const decoded = jwt_decode(localStorage.getItem('access'));
            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (decoded.exp < currentTimestamp) {
                localStorage.clear();
                if (current_url === "/login") {
                    Content = <Login/>;
                } else {
                    this.props.history.push('/login');
                }
            } else {
                if (current_url === "/login") {
                    // Content = <LandingPage/>
                    this.props.history.push('/cms');
                } else {
                    Content = <LandingPage/>
                }
            }
        }
        return (
            <div>
                {Content}
            </div>
        )
    }
}

export default withRouter((Index));