import { api } from '../../api/';
// import * as types from '../constants/action-types';
// import { history } from '../helpers';

const getListFaq = () => {
  return async (dispatch) => {
      try {
          const apiEndpoint = 'faqs/';
          const response = await api.get_public(apiEndpoint);
          return response;
      } catch (error) {
            return error;
      }
  };
};

const getFaq = (id) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'faq/'+id+'/';
            const response = await api.get(apiEndpoint);
            return response;
        } catch (error) {
              return error;
        }
    };
};

const FaqOrder = (payload) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'faqs/order/';
            const response = await api.put(apiEndpoint,payload);
            return response;
        } catch (error) {
              return error;
        }
    };
};

const FaqEdit = (id,payload) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'faq/'+id+'/';
            const response = await api.put(apiEndpoint,payload);
            return response;
        } catch (error) {
              return error;
        }
    };
};

const FaqAdd = (payload) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'faqs/';
            const response = await api.post_auth(apiEndpoint,payload);
            return response;
        } catch (error) {
              return error;
        }
    };
};

const FaqDelete = (id) => {
    return async (dispatch) => {
        try {
            const apiEndpoint = 'faq/'+id+'/';
            const response = await api.deleted(apiEndpoint);
            return response;
        } catch (error) {
              return error;
        }
    };
};

export const auths = (type, data) => {
  return {
    type: type,
    data: data,
  }
}

export const FaqActions = {
    getListFaq,
    FaqOrder,
    getFaq,
    FaqAdd,
    FaqEdit,
    FaqDelete
}
