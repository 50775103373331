/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {connect} from 'react-redux';
// reactstrap components

// core components

import {LanguageActions} from "../../../../redux/actions";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang:'indo'
    }
  }

  handleLang = (lang) => {
    const {dispatch} = this.props;
    dispatch(LanguageActions.changeLanguage(lang));
    // IndexNavbar(lang)
    this.setState({
      lang:lang
    })
  }


  render(){
    return (
      <>
        <div style={{
            padding:'0 1rem',
            backgroundColor:'white',
            width:'100%',
            textAlign:'right',
            lineHeight:'5rem'
        }}>
            © Sierad Produce
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {language} = state;
  return {
    language
  };
};

// export default Index;
export default connect(mapStateToProps)(Index);
